	html {
		font-size : 62.5%; //LATEN STAAN - NIET AANPASSEN
		height: 100%; overflow-y: scroll;
		-webkit-font-smoothing: antialiased;
		-webkit-overflow-scrolling : touch;
		-webkit-tap-highlight-color : rgba(0,0,0,0.1);
		-webkit-text-size-adjust : 100%;
		-ms-text-size-adjust : 100%;
	}

	body {
		color: $textcolor;
		background-color: lighten($bodycolor, 2);
		font-family: $font;
		line-height: $lineheight;
		@include font-size(14);
		@include mq($tablet, true) {
			@include font-size(16);
		}
	}

//	RAAMWERKEN

	#site {
		background-color: $bodycolor;
		width: 100%;
		max-width: 1800px;
		margin: 0 auto;
		overflow: hidden;

		#detail & {
			background: $wit;
		}

		.lt_ie9 & {
			min-width: 1240px;
		}

	}

//	DETAIL

	#detail {
		main {
			border-bottom: 2px solid darken($bodycolor, 10);
		}
	}

//	SUBPAGE

	#subpage {
		main {
			@include mq($smalltablet, false) {
				margin-bottom: $margin-bottom;
			}
			@include mq($desktop, true) {
				float: left; display: inline;
				width: 600px; margin: $margin-bottom 0 0;
			}
			@include mq($widescreen, true) {
				width: 700px;
			}
			&.fullwidth {
				width: 100% !important;
			}
		}

	aside {
			@include mq($smalltablet, false) {
				width: 280px; margin-bottom: $margin-bottom;
				&#first {
					float: left; display: inline;
				}
				&#second {
					float: right; display: inline;
				}
			}
			@include mq($tablet, false) {
				width: 355px;
			}
			@include mq($desktop, true) {
				float: right !important; clear: right;
				width: 306px; margin-right: 2px;
				margin: $margin-bottom 0 0;
				section {
					position: relative; top: 12px;
				}
			}
			@include mq($widescreen, true) {
				width: 380px; margin-right: 0;
			}
		}
	}

//	GRID

	.container, #container {
		margin: 0 10px;

		.col {
			float: left; display: inline;
			margin: 0 10px;
		}

		#header &.cols2 {
			.col {
				display: none;
				&.first {
					display: inline-block;
					width: auto;

					section * {
						display: none;
					}

					section h1 {
						display: block;
					}
				}
			}
		}

		@include mq($mobile, true) {
			width: 380px;
			margin: 0 auto;

		}

		@include mq($smalltablet, false) {
			width: 580px;

			#header &.cols2 {
				.col {
					&.first {

						section * {
							display: inline-block;
						}
					}
				}
			}
		}

		@include mq($tablet, false) {
			width: 730px;
			margin: 0 auto;

			&.cols1, &.cols2, &.cols3 {
				width: 750px;
			}

			&.cols1 .col {
				width: 730px;
			}

			#header &.cols2 {
				.col {
					display: none;
					&.first {
						display: block;
						width: 730px;
					}
				}
			}

			footer &.cols2 {
				margin-bottom: $margin-bottom;
			}

			&.cols2 .col {
				width: 355px;
			}

			&.cols3 .col {
				width: 230px;
			}

		}

		@include mq($desktop, false) {
			width: 960px;
			margin: 0 auto;

			&.cols1, &.cols2, &.cols3 {
				width: 980px;
			}

			&.cols1 .col {
				width: 960px;
			}

			#header &.cols2 {
				.col {
					display: inline;
					&.first {
						display: inline;
						width: 450px;
					}
				}
			}

			&.cols2 .col {
				width: 470px;
				footer & {
					width: 450px;
					&.first {
						margin-right: 50px;
					}
				}
			}

			&.cols3 .col {
				width: 306px;
			}

		}

		@include mq($widescreen, true) {
			width: 1180px;
			margin: 0 auto;

			&.cols1, &.cols2, &.cols3 {
				width: 1200px;
			}

			&.cols1 .col {
				width: 1180px;
			}

			#header &.cols2 {
				.col {
					display: inline;
					&.first {
						display: inline;
						width: 540px;
					}
				}
			}

			footer &.cols2 {
				margin-bottom: $margin-bottom * 2;
			}

			&.cols2 .col {
				width: 580px;
				footer & {
					width: 540px;
					&.first {
						margin-right: 90px;
					}
				}
			}

			&.cols3 .col {
				width: 380px;
			}

		}
	}

#subpage #container {
	@include mq($desktop, true) {
		margin-bottom: $margin-bottom;
	}
}

	.lt_ie9 footer .container.cols2 .col {
		width: 540px;
		&.first {
			margin-right: 90px;
		}
	}

.col-box {
  @include clearfix();


  &__label {
    @include mq($smalltablet) {
      float: left;
      display: inline;
      width: 220px;
    }

  @include mq($tablet) {
      float: none;
      display: block;
      width: 100%;
    }

    @include mq($desktop) {
      float: left;
      display: inline;
      width: 220px;
    }

    @include mq($widescreen) {
      width: 265px;
    }

    p {
      margin-bottom: 0;
    }

    figure {
      max-width: 100%;
      margin-top: 10px;
      margin-right: 20px;
    }
  }

  &__field {
    @include mq($smalltablet) {
      float: left;
      display: inline;
      width: calc(100% - 220px);
    }

    @include mq($tablet) {
      float: none;
      display: block;
      width: 100%;
    }

    @include mq($desktop) {
      float: left;
      display: inline;
      width: calc(100% - 220px);
    }

    @include mq($widescreen) {
      width: calc(100% - 265px);
    }
  }

  &--rentalpoint {
    padding-top: 20px;
    position: relative;

    @include mq($smalltablet) {
      min-height: 90px;
    }

    figure {
      display: none;

      @include mq($smalltablet) {
        position: absolute;
        left: 0;
        top: 20px;
        max-width: 100px !important;
        margin: 0;
        display: block;

        img {
          max-height: 90px;
        }
      }
    }

    p {
      @include mq($smalltablet) {
        padding-left: 110px;
      }
    }

    td {
      background: #fff;
    }
  }


}


.new--price {
  padding-top: 20px;
  margin-bottom: 35px;
}

.fullwidth > *:not(#catalogus) {
	max-width: 700px;
}


//	TITELS

	h1, h2, h3, h4, h5{
		font-family: $titel;
		line-height: $titlelineheight;
		font-weight: normal;
		color: $accent;
		margin-bottom: .2em;
		a{
			text-decoration: none;
		}

    &.black {
      color: #000;
    }

    &.title--new {
      margin-bottom: 20px;
    }
	}

	h1, h2.titel, h3.titel, .pagetitle h2 {
		@include font-size(24);
		margin-bottom: $margin-bottom;
		@include mq($smalltablet, false) {
			@include font-size(28);
		}
		@include mq($tablet, false) {
			@include font-size(36);
		}
		@include mq($desktop, false) {
			@include font-size(42);
		}
		@include mq($widescreen, true) {
			@include font-size(46);
		}
	}

  h2.title--new {
    margin-top: 10px;
    @include font-size(24);
    margin-bottom: $margin-bottom;
    @include mq($smalltablet, false) {
      @include font-size(28);
    }
    @include mq($tablet, false) {
      @include font-size(36);
    }
  }

	aside h3.titel{
		@include font-size(16);
		@include mq($smalltablet, false) {
			@include font-size(20);
		}
		@include mq($tablet, false) {
			@include font-size(20);
		}
		@include mq($desktop, false) {
			@include font-size(24);
		}
		@include mq($widescreen, true) {
			@include font-size(32);
		}
	}

	#home #container main h1,
	#home #container main .pagetitle h2 {
		text-align: center;
	}

	#home h1.titel, h2.titel {
		margin-top: $margin-bottom;
		.checkout &{
				margin-top: 0;
				margin-bottom: $margin-bottom;
			}
		@include mq($tablet, false) {
			.checkout &{
				margin-top: 7px;
				@include font-size(24);
			}
		}
		@include mq($desktop, false) {
			@include font-size(32);
			margin-bottom: 10px;
			.checkout &{
				@include font-size(30);
				margin-top: 7px;
				margin-bottom: $margin-bottom;
			}
		}
		@include mq($widescreen, true) {
			@include font-size(46);
			margin-bottom: .2em;
			margin-top: $margin-bottom * 1.5;
			.checkout &{
				@include font-size(36);
				margin-top: 7px;
				margin-bottom: $margin-bottom;
			}
		}
	}

	h2{
		@include font-size(18);
		@include mq($smalltablet, false) {
			@include font-size(22);
		}
		@include mq($desktop, true) {
			@include font-size(24);
		}
	}

	h3{
		@include font-size(16);
		@include mq($smalltablet, false) {
			@include font-size(20);
		}
		@include mq($desktop, true) {
			@include font-size(22);
		}
	}

	h4{
		@include font-size(16);
		@include mq($smalltablet, false) {
			@include font-size(18);
		}
		@include mq($desktop, true) {
			@include font-size(20);
		}
	}

	h5{
		@include font-size(14);
		@include mq($smalltablet, false) {
			@include font-size(16);
		}
		@include mq($desktop, true) {
			@include font-size(18);
		}
	}

//	PARAGRAAF

	p{
		margin: 0 0 $margin-bottom;
		&.inleiding{
			@include font-size(14);
			line-height: $lineheight;
			color: $donkergrijs;
			@include mq($smalltablet, false){
				@include font-size(16);
			}
			@include mq($tablet, true){
				@include font-size(18);
			}
		}


		@include mq($desktop, false) {
			#header &{
				@include font-size(14);
				line-height: 20px;
				margin-bottom: 10px;
			}
		}
		@include mq($widescreen, true) {
			#header &{
				@include font-size(16);
				line-height: $lineheight;
				margin-bottom: $margin-bottom;
			}
		}
	}

	sub{ font-size: 80%; position: relative; top: 3px; }
	sup{ font-size: 80%; position: relative; top: -3px;}

//	FAQ/ACCORDION

	.faq{
		background: darken($lichtergrijs, 5);
		border: 1px solid darken($lichtergrijs, 5);
		display: block;
		margin-bottom: $margin-bottom;
		padding: 5px 10px;

		&.noMargin{
			margin-bottom: 5px;
		}

		.question{
			position: relative;
			display: block;
			@include font-size(14);
			font-weight: bold;
			line-height: $lineheight;
			font-family: $font;
			text-decoration: none;
			padding-left: $gutter;
			color: $accent;
			i{
				position: absolute; left: -4px; top: 0;
				@include font-size(22);
			}
			i.icon-minus-sign-alt{
				display: none;
			}
			i.icon-plus-sign-alt{
				display: inline-block;
				width: 16px;
			}
			&.active{
				color: $hoveraccent;
				i.icon-minus-sign-alt{
					display: inline-block;
					width: 16px;
				}
				i.icon-plus-sign-alt{
					display: none;
				}
			}
		}
		.answer{
			p{
				margin: 0 0 0 $gutter;
			}
		}
		@include mq($smalltablet, true){
			padding: 10px;
			.question{
				@include font-size(16);
			}
		}
	}


//	OPSOMMINGEN

	.opsomming{
		margin: 0 0 $margin-bottom 24px;
		li{
			color: lighten($textcolor, 25);
			span{
				color: $textcolor;
			}
		}
		&.articles, &.calendar{
			list-style: none;
			margin: 0 0 $margin-bottom;
			border-top: 1px solid $lichtergrijs;
			line-height: $altlineheight;
			li{
				border-bottom: 1px solid $lichtergrijs;
				a{
					display: block; padding: 5px 0;
					text-decoration: none;
					span.titel{
						color: $accent;
						display: block;
						margin-bottom: 5px;
					}
					span.date, span.location{
						display: block;
						color: $grijs;
						@include font-size(12);
					}
					&:hover span.titel{
						color: $hoveraccent;
					}
				}
			}
		}
	}

	.usp{
		margin: 0 0 $margin-bottom; padding: 0;
		list-style: none;
		display: block;
		#detail & {
			@include mq($mobile, true, max, width) {
				display: none;
			}
		}
		li{
			//float: left; display: inline;
			margin: 0 0 5px;
			@include titel();
			@include font-size(16);
			color: $accent;
			line-height: $altlineheight;
			.product &{
				position: relative; padding-left: 28px;
				i{
					position: absolute; left: 0; top: 3px;
				}
			}
		}

		@include mq($tablet, false){
			li{
				@include font-size(18);
			}
		}

		@include mq($desktop, false){
			li{
				@include font-size(20);
			}
		}

		@include mq($widescreen, true){
			li{
				@include font-size(22);
			}
		}
	}


.usp--new {
    margin: 0 0 $margin-bottom; padding: 0;
    list-style: none;
    display: block;

    li{
      //float: left; display: inline;
      margin: 0 0 15px;
      line-height: $altlineheight;
      padding-left: 25px;
      position: relative;

      i {
        position: absolute;
        left: 0;
        top: -4px;
        font-size: 21px;
        color: $accent2;
      }
    }
  }

//	LINKS

	a{
		color: $accent;
		&:hover{
			color: $hoveraccent;
		}
	}

	a.zoekWoord{
		text-decoration: none;
		background: $info;
		padding: 3px;
	}

	// BUTTON

	a.btn, button{
		position: relative; top: 10px;
		background: $accent; display: inline-block;
		margin-bottom: $margin-bottom;
		color: $wit;
		padding: 8px 10px;
		@include font-size(18);
		font-family: $titel;
		line-height: $altlineheight;
		font-weight: bold;
		text-decoration: none;
		white-space: nowrap;
		text-transform: uppercase;
		text-align: center;
		border-bottom: 4px solid $hoveraccent;

		//@include rounded(4px);
		//@include transition(all, .2, ease);
		&:hover{
			top: 13px;
			//background: $hoveraccent;
			border-bottom: 1px solid $hoveraccent;
			margin-bottom: $margin-bottom + 3;
		}

		&.back{
			background: $grijs;
			border-bottom: 4px solid $donkergrijs;
			&:hover{
				border-bottom: 1px solid $donkergrijs;
			}
		}

	}

//	AFBEELDINGEN

	figure{
		margin: 0 auto $margin-bottom;
		max-width: 320px;
		width: 100% !important;
		clear: both;

		#header .col &{
			margin-bottom: 0;
		}

		@include mq($smalltablet, true) {
			max-width: 100%;
			width: auto !important;
			&.left{
				float: left;
				display: inline;
				clear: none;
				max-width: 49%;
				margin: 0 2% $margin-bottom 0;
			}
			&.right{
				float: right;
				display: inline;
				clear: none;
				max-width: 49%;
				margin: 0 0 $margin-bottom 2%;
			}
			&.noMargin{
				margin: 0 0 $margin-bottom;
			}
		}

		img{
			display: block;
			//margin: 0 auto;
			max-width: 100%;
		}

		figcaption{
			display: block; margin: 6px auto 0;
			text-align: center;
			@include font-size(12);
			line-height: $lineheight;
			color: lighten($textcolor, 25);
		}
	}

	.item1014 figure,
  .item1152 figure {
		max-width: auto;
		float: left;
		padding: 10px;
		clear: none;
	}

//	YOUTUBE

	.video{
		float: left; display: block; clear: left;
		width: 100%;
		margin: 0 0 $margin-bottom;

		.videoWrapper{
			position: relative; overflow: hidden;
			width: 100%; height: 0;
			margin: 0 auto; padding: 0 0 56.25%; /* 16/9 ratio*/

			iframe, object, embed{
				position: absolute; top: 0; left: 0;
				width: 100% !important; height: 100% !important;
			}
		}
	}

//	TABELLEN

	table{
		margin-bottom: $margin-bottom;
		width: 100%;
		text-align: left;
		color: $donkergrijs;

		tr{
			td, th{
				padding: 3px 5px;
				border: 1px solid darken($lichtergrijs,10);
				@include font-size(13);
				line-height: $altlineheight;
				font-weight: normal;
				text-align: left;
				@include mq($smalltablet, true){
					padding: 5px;
					@include font-size(13);
				}
			}

			th, td.first{
				background-color: darken($lichtergrijs, 3) !important;
				font-weight: bold;
				color: darken($donkergrijs, 10%);
			}

			&.odd td, td.even{
				background-color: lighten($lichtergrijs, 3);
			}
		}





	}

//	FORMULIER

	input[type="radio"] {    vertical-align: text-bottom; }
	input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

	#content form{
		float: left; display: inline; clear: both;
	}

	form{
		margin: 0; padding: 0;
		clear: both;
		width: 100%;
	}

	.formulier, .aanvraagformulier{
		clear: both;
		margin-bottom: $margin-bottom;
		overflow: hidden;
		background: lighten($lichtergrijs, 5);
		border: 1px solid $lichtergrijs;
		padding: 10px;

		.clearfix {
			margin-bottom: $margin-bottom / 2; zoom: 1;
			&:before, &:after {
				display: table;
				content: "";
				zoom: 1;
			}
			&:after {
				clear: both;
			}
		}

		label{
			float: left; display: inline;
			width: 100%; padding-top: 0; margin-bottom: 3px;
			line-height: $altlineheight;
			font-family: $font;
			color: $donkerdergrijs;
			text-align: left;
			//@include font-size(13);

			.required{
				@include font-size(18);
				color: $error;
			}
		}

		.input{
			clear: left;
			margin-left: 0;

			.inputs-list {
				margin: 0 0 5px;
				width: 100%;
				li{
					display: block;
					padding: 0;
					width: 100%;
				}
				label {
					display: block;
					float: none;
					width: auto;
					padding: 0 !important;
					margin-left: 20px;
					line-height: 18px;
					text-align: left !important;
					white-space: normal;
					width: 92% !important;
				}
				&:first-child{
					padding-top: 6px;
				}
				input[type=radio], input[type=checkbox] {
					margin-bottom: 0;
					margin-left: -20px;
					float: left;
				}
			}
		}

		input, select, textarea{
			display: inline-block;
			@include font-size();
			font-family: $font;
			font-weight: normal;
			line-height: normal;
			width: 97%; height: 18px; padding: 4px 1%;
			color: $donkergrijs; border: 1px solid $lichtgrijs;
			//@include rounded(3px);
		}

		input, textarea{
			@include transition(all, .2, linear);
			@include box-shadow(inset 0 1px 3px rgba(0,0,0,.1));
			&:focus {
				outline: 0;
				border-color: lighten($grijs, 10%);
				@include box-shadow(0 0 8px rgba(0,0,0,.2));
			}
			&.mini{width: 50px !important;}
			&.small{ width: 80px !important;}
			&.placeholder{
				color: $grijs;
				font-weight: normal;
			}
		}

		textarea {
			height: auto; overflow-y: auto;
			resize: vertical;
			width: 97%;
			height: 140px; max-height: 400px;
		}

		select{
			height: 27px;
			*height: auto;
			line-height: 27px;
			*margin-top: 4px;
			padding: initial; width: 100%;
			&.mini{width: 60px !important;}
			&.small{width: 90px !important;}
			&:focus{
				@include box-shadow(none);
				outline: 1px dotted $donkergrijs;
			}
		}

		input{
			&[type=checkbox], &[type=radio]{
				cursor: pointer;
				width: auto !important; height: auto !important;
				padding: 0; margin: 3px 0;
				*margin-top: 0;
				line-height: normal;
				border: none;
			}
			.ie9 &[type=checkbox]{
				position: relative; top: -1px;
			}

			&[type=file]{
				background-color: $wit;
				padding: initial;
				border: initial;
				line-height: initial;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
				&:focus{
					@include box-shadow(none);
					outline: 1px dotted $donkergrijs;
				}
			}
		}

		button{
			top: 5px;
			cursor: pointer;
			border: 0;
			outline: 0;
			//background: $oranje;
			margin: 0 0 10px;
			-webkit-font-smoothing: antialiased;
			font-weight: normal;
			border-bottom: 4px solid $hoveraccent;
			&:hover{
				top: 8px;
				border-bottom: 1px solid $hoveraccent;
				margin-bottom: 13px;
			}
		}

		//ERROR
		.clearfix.error{
			label, input, textarea{
				color: $error;
			}
			input, textarea, select {
				border-color: $error;
				&:focus{
					@include box-shadow(0 0 6px lighten($error,25%));
				}
			}
		}

		@include mq($smalltablet, false) {
			label{
				@include font-size(14);
			}
			main &{
				padding: $margin-bottom 10px;
				label{
					padding-top: 6px;
					margin-bottom: 0;
					width: 30%;
					text-align: right;
				}
				.input{
					clear: none;
					margin-left: 32%;
				}

				input{
					width: 70%;
				}
				select{
					width: 72%;
				}
				textarea{
					height: 200px; width: 90%;
				}
				button{
					margin-left: 32%;
				}
			}
		}

		.lt_ie9 main &{
			label{
				padding-top: 6px;
				margin-bottom: 0;
				width: 30%;
				text-align: right;
			}
			.input{
				clear: none;
				margin-left: 32%;
			}

			input{
				width: 70%;
			}
			select{
				width: 72%;
			}
			textarea{
				height: 200px; width: 90%;
			}
			button{
				margin-left: 32%;
			}
		}
	}

	.checkout .aanvraagformulier{
		background: transparent;
		border: 0;
		padding: 0;
		 label{
		 	font-family: $titel;
			line-height: $titlelineheight;
			font-weight: normal;
			color: $zwart;
			padding-top: 6px;
			margin-bottom: 4px;
			width: 100%;
			text-align: left;
			@include font-size(16);
			@include mq($smalltablet, false) {
				@include font-size(20);
			}
			@include mq($desktop, true) {
				@include font-size(22);
			}
		}
		.input{
			clear: both;
			margin-left: 0;
			.inputs-list{
				label{
					font-family: $font;
					@include font-size(14);
					a{
						color: $zwart;
						&:hover{
							color: $accent;
						}
					}
				}
			}
		}

		.error .input .inputs-list label a{
			color: $accent;
		}

		input{
			width: 97%;
			@include mq($tablet, false) {
				height: 20px;
			}
			@include mq($desktop, false) {
				height: 22px;
			}
			@include mq($widescreen, true) {
				height: 27px;
				@include font-size(16);
			}
		}
	}

	:-moz-placeholder {color: $grijs;}
	::-moz-placeholder {color: $grijs;}
	::-webkit-input-placeholder {color: $grijs;}





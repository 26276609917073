/*	Config =================================================== */

//	SFEER

	$bodycolor: #f0f0f0;
	$textcolor: #010101;

	$accent: #0062ac;
	$hoveraccent: darken($accent, 10);

	$accent2: #ffdf0f;
	$hoveraccent2: darken($accent2, 10);


//	BREAKPOINTS (MOBILE FIRST)

	$mobile: 480;  //MOBIEL (LANDSCAPE)
	$smalltablet: 600;  //KLEINE TABLETS
	$tablet: 768;  //IPAD
	$desktop: 1024; //DESKTOP
	$widescreen: 1200;	//WIDESCREEN

//	AFMETINGEN

	$gutter: 20px; //TUSSENRUIMTE
	$margin-bottom: 20px; //MARGIN TUSSEN LAYOUTMODELLEN

//	STANDAARD KLEUREN

	$zwart: #000;
	$donkerdergrijs: #333;
	$donkergrijs: #666;
	$grijs: #999;
	$lichtgrijs: #b4b4b4;
	$lichtergrijs: #eee;
	$wit: #fff;

	$twitter: #35ccff;
	$facebook: #03539e;
	$linkedin: #217bc1;

	$error: #E41B13; //ROOD
	$ok: #7AB317; //GROEN
	$info: #FFF2AF; //BOTERGEEL

//	TYPOGRAFIE

	$lineheight: 1.6em;
	$altlineheight: 1.1em; //WORDT OA GEBRUIKT VOOR ONDERSCHRIFT, TABELLEN, BUTTONS, OPSOMMINGEN
	$titlelineheight: 1.0em; //WORDT GEBRUIKT VOOR TITELS

	$font: Arial, "Helvetica Neue", Helvetica, sans-serif;
	$titel: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
	$code: Monaco, Consolas, "Lucida Console", monospace;

	@mixin titel() {
		font-family : "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
		font-weight : normal;
		font-style  : normal;
	}

	//	VEELGEBRUIKTE FONT-STACKS:
	//	Arial	 		: Arial, "Helvetica Neue", Helvetica, sans-serif;
	//	Courier New		: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
	//	Georgia			: Georgia, Times, "Times New Roman", serif;
	//	Helvetica		: "Helvetica Neue", Helvetica, Arial, sans-serif;
	//	Lucida Grande	: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
	//	Tahoma			: Tahoma, Verdana, Segoe, sans-serif;
	//	Times New Roman	: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
	//	Trebuchet MS	: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
	//	Verdana			: Verdana, Geneva, sans-serif;

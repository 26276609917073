#returntime,
#pickuptime {
	padding: 7px;
}

.exclzat {
	color: $grijs;
	font-size: 75%;
}

p.datetext {
	border-bottom: 1px solid $lichtergrijs;
	line-height: 1.25;
	margin-bottom: 0;
	padding: 8px 0;
}

p.datetext--first {
	border-top: 1px solid $lichtergrijs;
}

p.datetext--last {
	margin-bottom: 20px;
}

#catalogus {

	h2 {
		color: $zwart;
		position: relative;
		text-align: center;
		top: -15px;
	}

	.products {

	}

	.product {
		background-color: $wit;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 10px;
		position: relative;
		margin-bottom: 20px;

		@include mq($desktop) {
		  padding: 20px;
		  flex-wrap: nowrap;
		}

		&--onlylimit,
		&--notavailable {
			&:before {
				background-color: rgba($wit, .6);
				bottom: 0;
				content: '';
				cursor: default;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 2;
			}

			&:hover {
				&:before {
					display: none;
				}

				.product__overlay {
					display: flex;
				}
			}
		}

		> * {
			box-sizing: border-box;
		}


		&__image {
			flex: 0 0 100%;

			@include mq($tablet, false) {
				flex: 0 1 25%;
				max-width: 25%;
			}

			@include mq($desktop) {
				border-right: 1px solid rgba($grijs, .5);
				flex: 0 1 30%;
				max-width: 30%;
			}

			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;

				@include mq($tablet) {
				  margin: 0;
				}

			}
		}

		&__meta {
			flex: 1 0 100%;

			@include mq($tablet, false) {
				flex: 0 1 33%;
				max-width: 33%;

        &--width {
          flex: 0 1 66%;
          max-width: 66%;

          table {
            float: left;
            width: 49%;
            display: inline;
          }
        }
			}

			@include mq($desktop, false) {
				flex: 0 1 30%;
				max-width: 30%;
				padding: 0 20px;
			}
		}

		&__information {
			flex: 1 0 100%;
			font-size: 90%;

      &--inside-meta {
        .product__price {
            margin-top: 0;
          }
      }

			@include mq($tablet, false) {
				flex: 0 1 25%;
				max-width: 25%;

        &--inside-meta {
          flex: none;
          float: right;
          display: inline;
          width: 66%;
          max-width: 66%;
        }
			}

			@include mq($desktop) {
			  padding: 0 20px;

         &--inside-meta {
          flex: none;
          display: block;
          max-width: 100%;
          width: 100%;
          padding: 0 !important;
        }
			}

      &--inside-meta {
        .product__price {
          margin-bottom: 0;
        }
      }

			.link {
				text-decoration: none;
			}
		}

		&__action {
			flex: 1 0 100%;

			@include mq($desktop, false) {
				flex: 0 1 25%;
				max-width: 25%;
				padding: 0 20px;

        &--width {
          flex: 0 1 40%;
          max-width: 40%;
        }
			}

			.btn {
				display: block;

				@include mq($desktop, false) {
					margin-top: 1.8em;

          #subpage & {
            margin-top: 10px;
          }
				}


			}
		}

		&__overlay {
			align-items: center;
			background-color: rgba($wit, .95);
			bottom: 0;
			cursor: default;
			display: none;
			flex-direction: column;
			justify-content: center;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;
		}

		&__title {
			color: $accent;
			margin-bottom: 20px;
			text-align: left;
			top: 0;
		}

		.table {
			border: 0;

			tr {
				td {
					border: 0;
					color: $zwart;
					padding-left: 0;

					&:first-child {
						font-weight: bold;
					}
				}
			}
		}

		&__price {
			background-color: rgba($accent2, .3);
			border: 1px solid rgba($accent2, .5);
			border-radius: 4px;
			margin-top: 20px;
			padding: 10px;
		}

		.price {
			&__3-hours {
				color: $accent;
				font-size: 115%;
				font-weight: bold;
			}
		}
	}
}

//	DETAIL

.productdetail {
	background: $bodycolor;
	.product {
		margin: 0 10px; padding: 10px 0;
		#detailContent {
			display: none;
		}

		.mobile--hide {
			@include mq($mobile, true, max, width) {
				display: none;
			}
		}

		h1 {
			color: $zwart;
			margin: 0;
		}

		h2 {
			color: $zwart;
			margin-bottom: $margin-bottom;
			@include mq($mobile, true, max, width) {
				margin-bottom: 0;
			}
			span {
				display: block;
				margin-right: $margin-bottom;
				@include mq($smalltablet, false) {
					display: inline;
				}
				@include mq($tablet, false) {
					display: block;
				}
				@include mq($desktop, true) {
					display: inline;
				}
			}
		}

		h3 {
			color: $zwart;
		}

		#slideshowholder {
			position: relative;
			padding-right: 124px;
			background: $wit;

			&.onlyone {
				padding-right: 0;
			}
		}

		#slideshow-1 {
			width: 100%; max-width: 540px; margin: 0 auto ($margin-bottom / 2);
			#cycle-1, .cycle-slideshow {
				div {
					padding: 10px;
					background: $wit;
					img {
						width: 100%;
						height: auto; display: block;
					}
				}
			}
		}

		#slideshow-2 {
			position: absolute;
			top: 10px;
			right: 10px;
			height: 100%;
			z-index: 100;
			margin: 0 auto $margin-bottom;
			// padding: 5px 0;

			.cycle-slide img { border: 2px solid #e7e7e7;}
			.cycle-slide-active img { border: 2px solid $accent; }

			img {
				display: block; margin: auto; max-width: 100px;
				cursor: pointer;
				background: $wit;
			}
		}
	}


	.properties {
		margin-bottom: 20px;
		overflow: hidden;
		font-family: $titel;

		.property {
			position: relative;
			display: block;
			float: left;
			width: 100%;
			@include mq($tablet, true) {
				width: 50%;
			}
			@include mq($desktop, true) {
				width: 33.33%;
			}
			text-indent: 35px;
			line-height: 24px;
			margin-bottom: 5px;
		}

		.icon {
			position: absolute;
			top: 0;
			left: 0;
			height: 24px;

			&--afmeting {
				width: 30px;
				background: url(../img/icon_afmetingen.png) 50% 0 no-repeat;
			}

			&--lading {
				width: 30px;
				background: url(../img/icon_belading.png) 50% 0 no-repeat;
			}

			&--rijbewijs {
				width: 30px;
				background: url(../img/icon_rijbewijs.png) 50% 0 no-repeat;
			}
			&--gewicht {
				width: 30px;
				background: url(../img/icon_gewicht.png) 50% 0 no-repeat;
			}
			&--geremd {
				width: 30px;
				background: url(../img/icon_geremd.png) 50% 0 no-repeat;
			}
		}
	}

	@include mq($smalltablet, false) {
		.product {
			padding-bottom: $margin-bottom;
		}
	}

	@include mq($tablet, true) {
		float: left; display: inline;
		width: 50%;
		@include font-size(14);
		line-height: $lineheight;
		.product {
			float: right; display: inline;
			width: 355px; padding-bottom: $margin-bottom * 2;
			#detailContent {
				display: block;
			}
		}

	}

	@include mq($desktop, false) {

		.product {
			width: 450px;
			margin-right: 30px;
		}

	}

	@include mq($widescreen, true) {
		@include font-size(16);
		line-height: $lineheight;
		.product {
			width: 540px;
			margin-right: 50px;
		}

	}

}

//	CHECKOUT
.wrapper {

  &--2 {
    background: $bodycolor;
  }

	.checkout {
		margin: 0 10px; padding: 10px 0;
		.clearfix {
			display: block; clear: both;
			.input {
				clear: left;
				float: left;
				margin-left: 0;
				width: 100%;
			}
		}
		.ophalen, .terugbrengen {
			.clearfix {
				margin-bottom: $margin-bottom / 2; zoom: 1;
				&:before, &:after {
					display: table;
					content: "";
					zoom: 1;
				}
				&:after {
					clear: both;
				}
				&.hidden {
					display: none;
				}
				&.error {
					label {
						color: $accent;
					}
					input {
						border: 1px solid $accent;
					}
				}
				label {
					display: block;
					font-family: $titel;
					line-height: $titlelineheight;
					font-weight: normal;
					@include font-size(16);
					color: $zwart;
					margin-bottom: 5px;
				}
				input, select {
					border: 1px solid $lichtgrijs;
					height: 19px; width: 97%;
					padding: 4px 5px;
					@include font-size(14);
					@include transition(all, .2, linear);
					@include box-shadow(inset 0 1px 3px rgba(0,0,0,.1));
					&:focus {
						outline: 0;
						border-color: lighten($grijs, 10%);
						@include box-shadow(0 0 8px rgba(0,0,0,.2));
					}
					&#pickupdatepicker, &#returndatepicker {
						float: left; display: inline; width: 97%;
						background: $wit url("../img/calendar.png") right 50% no-repeat;
						cursor: pointer;
						@include retina() {
							background-image: url("../img/calendar@2x.png");
							background-size: 39px 100px;
						}
						.lt_ie9 & {
							background: $wit url("../img/calendar.png") right 50% no-repeat;
							background-size: 39px 100px;
						}
					}
				}
				select {
					height: 27px;
					*height: auto;
					line-height: 27px;
					*margin-top: 4px;
					padding: initial; width: 100%;
				}
				img.ui-datepicker-trigger {
					width: 29px; cursor: pointer;
					float: left; display: inline;
					display: none !important;
				}
			}
		}
		.message.error {
			background: $accent;
			margin-bottom: $margin-bottom;
			text-align: center;
			font-weight: bold;
			@include font-size(14);
			line-height: $lineheight;
			color: $wit;
			padding: 5px;
		}

		.actions {
			position: relative;
			clear: both;
			height: 40px; padding-top: 10px;
			margin-bottom: $margin-bottom;
			.btn {
				top: 0;
				width: 45%;
				padding-left: 0; padding-right: 0;
				&:hover {
					top: 3px;
				}
			}
			button {
				float: right; display: block;
				top: 0;
				cursor: pointer;
				border: 0; outline: 0;
				border-bottom: 4px solid $hoveraccent;
				margin: 0;
				-webkit-font-smoothing: antialiased;
				font-weight: normal;
				width: 45%;
				//margin-right: 1%;
				&:hover {
					top: 3px;
					border-bottom: 1px solid $hoveraccent;
				}
			}
		}
		.col {
			h3 {
				color: $zwart;
			}
		}
	}

	@include mq($smalltablet, false) {
		.checkout {
			.ophalen, .terugbrengen {
				float: left; display: inline;
				width: 45%; margin-right: 10%;
				.clearfix {
					label {
						@include font-size(20);
					}
				}
			}
			.terugbrengen {
				margin-right: 0;
			}
			.actions {
				button, .btn {
					width: 45%;
					@include font-size(18);
				}
			}
		}
	}

	@include mq($tablet, true) {
		float: left; display: inline;
		width: 50%;
		.checkout {
			float: left; display: inline;
			width: 355px;

      &--2 {
        float: right;
      }
			@include font-size(14);
			line-height: $lineheight;
			.ophalen, .terugbrengen {
				width: 100%;
				.clearfix {
					label {
						@include font-size(18);
					}
				}
			}
		}
	}

	@include mq($desktop, false) {
		.checkout {
			width: 440px;
			margin-right: 30px;
			margin-left: 20px;
			@include font-size(14);
			line-height: $lineheight;
			.ophalen, .terugbrengen {
				float: left; display: inline;
				width: 210px;
				margin-right: 20px;
				.clearfix {
					label {
						@include font-size(20);
					}
					input, select {
						@include font-size(15);
						height: 24px;
						&#pickupdatepicker, &#returndatepicker {
							width: 198px;
						}
					}
					select {
						height: 34px;
						*height: auto;
						line-height: 34px;
					}
					img.ui-datepicker-trigger {
						width: 34px;
					}
				}
			}
			.terugbrengen {
				margin-right: 0;
			}
			.actions {
				button, .btn {
					width: 210px;
					@include font-size(20);
					margin-right: 0;
				}
			}
			.col {
				float: left; display: inline;
				width: 210px;
				margin-right: $margin-bottom;
				&.last {
					margin-right: 0;
				}
			}
		}
	}

	@include mq($widescreen, true) {
		.checkout {
			width: 520px;
			margin-right: 50px;
			margin-left: 30px;
			.ophalen, .terugbrengen {
				width: 250px;
				margin-right: 20px;
				.clearfix {
					label {
						@include font-size(22);
					}
					input, select {
						@include font-size(16);
						height: 29px;
						padding: 4px 10px;
						&#pickupdatepicker, &#returndatepicker {
							width: 228px;
						}
					}
					select {
						height: 39px;
						*height: auto;
						line-height: 39px;
						*margin-top: 4px;
						padding: initial; width: 100%;
					}
					img.ui-datepicker-trigger {
						width: 39px;
					}
				}
			}
			.terugbrengen {
				margin-right: 0;
			}
			.actions {
				button, .btn {
					width: 250px;
					@include font-size(24);
					margin-right: 0;
				}
			}
			.col {
				float: left; display: inline;
				width: 250px; margin-right: $margin-bottom;
				&.last {
					margin-right: 0;
				}
			}
		}
	}

	.ie7 & {
		float: left; display: inline;
		width: 49%;
	}

	.lt_ie9 & select {
		height: 32px !important;
		margin-top: 0 !important;
		line-height: 32px !important;
	}

	.lt_ie9 & .checkout .clearfix {
		display: block; clear: both;
		label {
			float: none;
			display: block;
			margin-bottom: 5px;
			text-align: left;
		}
		input {
			width: 97%;
		}
	}

	.ie8 & input {
		line-height: 28px !important;
	}

	.lt_ie9 & .actions .btn.back {
		float: left;
	}

	.ie9 & select {
		height: 32px !important;
		padding-top: 8px !important;
		line-height: 32px !important;
	}

}

.col--mobiel {
	display: inline;
	width: 100% !important;

	p {
		margin-bottom: 10px;
	}

	p.last {
		margin-bottom: 0;
	}

	@include mq($desktop, true) {
		display: none !important;
	}
}

.col--prijs {
	display: none;

	@include mq($desktop, true) {
		display: inline
	}
}

//	HEADER

	header {
		background: $wit;
		margin-bottom: $margin-bottom;
		position: relative; z-index: 101;
		#detail & {
			margin-bottom: 0;
		}

	//	TOPBALK MET LOGO EN USP'S
		#top {
			position: relative;
			display: block;
			max-width: 1180px;
			margin: 0 auto;
			padding: 20px 10px;

			.lt_ie9 & {
				width: 1180px;
			}

			#logo {
				width: 100%;

				a {
					margin: 0 auto 20px;
					display: block;
					width: 180px;

					body#detail & {
						@include mq($mobile, true, max, width) {
							margin-bottom: 0;
						}
					}

					img {
						width: 100%; display: block;
						margin: 0 ;
					}
				}
			}

			.phonelink {
				display: block;
				text-align: center;
				margin-bottom: 20px;
				@include titel();
				@include font-size(22);

				a {
					text-decoration: none;
				}

				@include mq($tablet, false) {
					position: absolute;
					top: 5px;
					right: 0;
					margin: 0;
				}

				@include mq($desktop, false) {

				}
			}

			ul {
				margin: 0 auto; padding: 0;
				list-style: none;
				display: block;
				width: 300px;
				li {
					float: left; display: inline;
					margin: 0 0 5px;
					@include titel();
					@include font-size(16);
					color: $accent;
					line-height: $altlineheight;
					width: 300px;
					//text-align: center;
					@include text-overflow();
					i {
						color: $accent2;
					}
				}
			}

			@include mq($mobile, false) {
				#logo {
					a {
						width: 200px;
					}
				}
				ul {
					width: 100%;
					li {
						width: 50%;
					}
				}
			}

			@include mq($smalltablet, false) {
				#logo {
					a {
						width: 210px;
					}
				}
				ul {
					width: 580px;
					li {
						@include font-size(18);
					}
				}
			}

			@include mq($tablet, true) {
				width: 730px;
				#logo {
					float: left; display: inline;
					width: auto;
					width: 220px;
					a {
						float: left; display: inline;
						margin: 0;
					}
				}
				ul {
					float: right; display: inline;
					width: 480px;
					li {
						width: 300px;
						margin: 5px 0 5px 20px;
						@include font-size(18);
					}
				}
			}

			@include mq($desktop, false) {
				width: 960px;
				ul {
					float: right; display: inline;
					width: 640px;
					margin-top: 15px;
					li {
						width: 300px;
						margin: 5px 0 5px 20px;
						@include font-size(18);
					}
				}
			}

			@include mq($widescreen, true) {
				width: 1180px;

				ul {
					float: right; display: inline;
					width: 860px;
					margin-top: 0;

					li {
						width: 380px;
						margin: 5px 0 5px 20px;
						@include font-size(20);
					}
				}
			}

			html[lang='fr'] & {
				margin-top: 0;

				li {
					display: block;
					float: none;
					margin-top: 0;
					width: auto;

					@include mq($mobile, true, max) {
						@include font-size(14);
					}
				}
			}

		} // EINDE #TOP

		#header {
			float: left;
			display: block;
			background: url("../img/bg.png") 50% no-repeat;
			max-height: 400px;
			width: 100%;
			overflow: hidden;
			@include mq($tablet, true) {
				display: block;
			}
			.col-right {
				img {
					width: auto;
					max-height: 200px;
					margin: 25px;
					float: right;
				}
			}
			img {
				display: block;
				margin: 0;
				width: 100%;
			}
		}

	//	TELEFOONNUMMER
		.phone {
			clear: both;
			display: block;
			background: $accent2;
			color: $accent;
			font-family: $titel;
			@include font-size(16);
			line-height: 28px;
			text-align: center;
			padding: 10px 0;
			span {
				display: none;
				@include mq($smalltablet, false) {
					display: block;
				}
				@include mq($desktop, true) {
					@include font-size(20);
					display: inline;
					position: relative; top: -2px; left: -10px;
				}
			}
			a {
				//font-family: $titel;
				color: $accent;
				@include font-size(28);
				text-decoration: none;
			}
		}

	} // EINDE HEADER

	//	SEARCH

		#search {
			display: block; clear: left;
			background: $accent2;
			//overflow: hidden;
			padding: 10px 0;

      &.search--sidebar {
        button {
          width: 100px !important;

          @include mq($tablet) {
            font-size: 20px !important;

            html[lang='fr'] & {
            	font-size: 15px !important;
            }
          }

        }

        input#searchinput {
          max-width: calc(100% - 20px);

          @include mq($smalltablet) {
            font-size: 15px !important;
          }
        }
      }

			@include mq($tablet, true) {
				padding: 30px 0 10px;
			}

			form {
				margin: 0 auto; padding: 0;
				display: block; position: relative;

				.tooltip {
					position: relative; //bottom: -45px; left: 0; right: 0; z-index: 2;
					@include titel();
					@include font-size(12);
					text-transform: uppercase;
					line-height: $titlelineheight;
					color: $accent2;
					background: $hoveraccent;
					padding: 5px 10px; margin-top: 5px;
					display: none;
					@include transition(bottom, .2, ease);

					&.show {
						display: block;
					}

					span {
						position: absolute;
						left: 5px; top: -20px;
						bottom: 100%;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-color: rgba(252, 255, 0, 0);
						border-bottom-color: $hoveraccent;
						border-width: 10px;
					}
				}

				label {
					display: block;
					@include titel();
					@include font-size(14);
					text-transform: uppercase;
					line-height: $titlelineheight;
					color: $accent;
					margin: 0 0 10px;
				}

				.searchinput {
					position: relative; overflow: hidden;
					display: block;
					margin: 0;

					input {
						float: left;
						display: inline;
						outline: none;
						border: none;
						@include rounded(0);
						-webkit-appearance: none;

						&.zoeken {
							background: $wit;
							border: 1px solid $lichtgrijs;
							outline: none;
							font-family: $font;
							@include font-size(16);
							height: 24px; width: 70%;
							padding: 2px 2px 2px 5px;
							margin: 0;
							color: $zwart;
							&.placeholder {
								color: $grijs;
							}
						}

						.checkout & {
							height: auto;
							width: 100% !important;
						}
					}

					.checkout & {
						padding-right: 110px;
						margin-bottom: 0;

						@include mq($smalltablet, false) {
							padding-right: 140px;
						}

						@include mq($tablet, false) {
							padding-right: 160px;
						}

						@include mq($desktop, false) {
							padding-right: 202px;
						}

						@include mq($widescreen, false) {
							padding-right: 225px;
						}
					}

					button {
						position: absolute; right: 0; top: 0;
						background: $accent;
						color: $wit !important;
						border: 0;
						width: 23%; height: 30px;
						border-bottom: 3px solid $hoveraccent; outline: 0;
						padding: 0; margin: 0;
						text-transform: uppercase;
						cursor: pointer;
						color: $grijs;
						@include font-size(12);

						html[lang='fr'] & {
							width: 27%;
						}

						&:hover, &:focus {
							color: $wit !important;
							background: darken($accent, 5) !important;
							border-color: darken($hoveraccent, 5);
						}
					}
				}
			}

			@include mq($mobile, false) {
				form {
					label {
						@include font-size(16);
					}
					.tooltip {
						@include font-size(14);
						span {
							left: 10px;
						}
					}
					.searchinput {
						input.zoeken {
							width: 260px;
							@include font-size(16);
						}
						button {
							width: 90px; height: 30px;
							@include font-size(18);
						}
					}
				}
			}

			@include mq($smalltablet, false) {
				form {
					label {
						@include font-size(18);
					}
					.tooltip {
						@include font-size(16);
					}
					.searchinput {
						input.zoeken {
							width: 430px;
							@include font-size(16);
						}
						button {
							width: 120px; height: 30px;
							@include font-size(20);
						}
					}
				}
			}

			@include mq($tablet, false) {
				form {
					label {
						@include font-size(20);
					}
					.tooltip {
						@include font-size(18);
						span {
							top: -20px;
							border-width: 10px;
						}
					}
					.searchinput {
						margin-bottom: 3px;
						input.zoeken {
							width: 560px;
							@include font-size(16);
							padding: 6px 2px 6px 5px;
						}
						button {
							width: 140px; height: 38px;
							border-bottom-width: 5px;
							@include font-size(24);
						}
					}
				}
			}

			@include mq($desktop, false) {
				form {
					label {
						@include font-size(22);
					}
					.tooltip {
						@include font-size(20);
						span {
							top: -24px;
							border-width: 12px;
						}
					}
					.searchinput {
						margin-bottom: 5px;
						input.zoeken {
							width: 746px;
							@include font-size(18);
							padding: 8px 2px 8px 10px;
						}
						button {
							width: 180px; height: 42px;
							border-bottom-width: 6px;
							@include font-size(28);
						}
					}
				}

			}

			@include mq($widescreen, true) {
				form {
					label {
						@include font-size(24);
					}
					.tooltip {
						@include font-size(22);
						width: 940px; margin-top: 0;
						span {
							top: -30px;
							border-width: 15px;
						}
					}
					.searchinput {
						margin-bottom: 10px;
						input.zoeken {
							width: 944px;
							@include font-size(20);
							padding: 10px 2px 10px 12px;
						}
						button {
							width: 200px; height: 46px;
							border-bottom-width: 8px;
							@include font-size(32);
						}
					}
				}
			}

		}

		#mapheader {
			display: none;
			background: $accent2;
			color: $wit;
			padding: 0;
			@include titel();
			@include font-size(14);
			text-transform: uppercase;
			line-height: $titlelineheight;
			.container {
				background: $accent;
				padding: 5px 0 3px;
			}
			div {
				padding: 0 10px;
			}
			span {
				color: $accent2;
				display: block;
				a {
					color: $accent2;
					@include font-size(12);
					&:hover {
						text-decoration: none;
					}
				}
			}
			&.show {
				display: block;
			}
			@include mq($smalltablet, false) {
					@include font-size(16);
					span {
						display: inline;
					}
			}
			@include mq($tablet, false) {
					padding: 0 0 12px;
					@include font-size(18);
					span {
						a {
							@include font-size(14)
						}
					}
			}
			@include mq($desktop, false) {
					padding: 0 0 15px;
					@include font-size(20);
			}
			@include mq($widescreen, true) {
					padding: 0 0 20px;
					@include font-size(22);
					span {
						display: inline;
						a {
							@include font-size(14)
						}
					}
			}
		}

		#mapfooter {
			clear: both;
			display: none;
			background: $accent;
			color: $accent2;
			line-height: $altlineheight;
			text-align: center;
			font-weight: bold;
			padding: 10px 0;
			@include box-shadow(inset 0 1px -3px rgba(0,0,0,.1));
			&.show {
				display: block;
			}
			@include mq($tablet, true) {
				line-height: $lineheight;
			}
		}

		#map {
			border-top: 10px solid $accent;
			float: left;
			height: 0; width: 100%;
			overflow: hidden;
			position: relative;
			@include transition(height, .2, ease);


			@include mq($tablet, true) {
				border-top: 10px solid $hoveraccent2;
			}

			.ie7 & {
				display: none;
			}
			&.show {
				display: block;
				height: auto;

				.ie7 & {
					display: block;
				}
			}

			.checkout & {
				height: auto;
			}

			#map_selected {
				padding: 20px;
				background-color: white;

				z-index: 10;
				box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.45);

				position: relative;
				left: 0;
				width: auto;
				margin-left: 0;
				top: 0;

				@include mq($smalltablet) {
					// width: 320px; min-height: 150px;
					// margin-left: ($smalltablet / 2) - 360px;
				}
				@include mq($tablet) {
					width: 320px; min-height: 150px;
					margin-left: ($tablet / 2) - 360px;

					left: 50%; top: 20px;
					position: absolute;
				}
				@include mq($desktop) {
					width: 320px; min-height: 150px;
					margin-left: ($desktop / 2) - 360px;
				}
				@include mq($widescreen) {
					width: 320px; min-height: 150px;
					margin-left: ($widescreen / 2) - 360px;
				}

				@include font-size(14);
				line-height: 20px;

				.location {
					padding-bottom: 10px;
					background: url(../img/marker_small.png) left top no-repeat;

					.location__detail__data {
						display: none;
					}

					// @include mq($smalltablet) {
					// 	&:nth-child(2) { display: block; }

					// 	&:nth-child(3), &:nth-child(4) { display: none; }
					// }
					@include mq($tablet) {
						&:nth-child(3) { display: block; }
						&:nth-child(5) { display: none; }
					}
					@include mq($desktop) {
						&:nth-child(1) {
							.location__detail__data {
								display: block;
							}
						}
						&:nth-child(4) { display: block; }
					}
					@include mq($widescreen) {
						&:nth-child(5) { display: block; }
					}

					.location__detail {
						margin-left: 40px;
						strong {
							display: block;
							margin-bottom: 5px;
							padding-top: 5px;
							@include font-size(16);
							cursor: pointer;
						}
						a.aanbod {
							display: inline-block;
							background-color: $accent;
							color: white;
							text-decoration: none;
							padding: 2px 10px;
							font-weight: bold;
							text-transform: uppercase;
							&:hover {
								background-color: $hoveraccent;
							}
						}
						span {
							display: block;
						}
					}
				}

			}

			#map_wrapper {
				width: 100%; height: 240px;

				#map_canvas {
					width: 100%; height: 240px;
				}
			}

			.infoBox {
				background: $wit;
				min-width: 220px;
				height: 120px;
				@include box-shadow(0 0 8px rgba(0,0,0,.5));
				img {
					position: absolute !important;
					right: 2px !important;
					top: 2px !important;
				}
				div {
					padding: 5px 10px 0; height: 100px;
					strong, span, a {
						font-family: $font;
						@include font-size(12);
						line-height: 17px;
						display: block;
						@include text-overflow();
					}
					strong {
						line-height: 18px;
					}
					a.aanbod {
						display: inline;
						background: $accent;
						color: $wit;
						margin: 0; padding: 3px 5px;
						text-decoration: none;
						text-transform: uppercase;
						font-weight: bold;
						font-family: $titel;
						&:hover, &:active {
							background: $hoveraccent;
						}
					}
				}
				figure {
					float: left;
					display: none;
					margin: 0 10px 0 0;
					img {
						position: static !important;
						display: block;
						height: 120px; width: 120px;
					}
				}
			}

			@include mq($mobile, false) {
				&.show {
					// height: 180px;
				}
				#map_wrapper {
					height: 180px;
					#map_canvas {
						height: 180px;
					}
				}
			}

			@include mq($smalltablet, false) {
				&.show {
					// height: 300px;
				}
				#map_wrapper {
					height: 300px;
					#map_canvas {
						height: 300px;
					}
				}
			}

			@include mq($desktop, false) {
				&.show {
					height: 360px;
				}
				#map_wrapper {
					height: 360px;
					#map_canvas {
						height: 360px;
					}
				}

				.infoBox {
					min-width: 400px;
					div {
						strong {
							@include font-size(14);
						}
						span, a {
							@include font-size(12);
						}
					}
					figure {
						display: inline;
					}
				}

			}

			@include mq($widescreen, true) {
				&.show {
					height: 400px;
				}
				#map_wrapper {
					height: 400px;
					#map_canvas {
						height: 400px;
					}
				}

				.infoBox {
					min-width: 400px;
					div {
						strong {
							@include font-size(14);
						}
						span, a {
							@include font-size(12);
						}
					}
					figure {
						display: inline;
					}
				}

			}

		}


.location-list-big {
	display: none;
	padding: 0 0 20px 0;
	overflow: hidden;

	.location {
		margin: 20px 0 0;
		width: 100%;
		float: left;

		@include mq($tablet, true) {
			width: 50%;
		}

		.location__detail {
			margin: 0;
			padding: 10px;
			background: #f0f0f0;
			cursor: pointer;
			overflow: hidden;

			@include mq($tablet, true) {
				margin: 0 10px 0;
			}

			&:hover {
				background: darken(#f0f0f0, 10%);
			}

			&.current {
				background: darken(#f0f0f0, 10%);
			}
		}

		span {
			display: inline-block;
			@include font-size(12);
			margin-right: 5px;

			span {
				margin-right: 0;
			}
		}

		.schedule {
			display: none;
		}

		a {
			display: block;
		}

		@include mq($desktop, true) {

			a {
				float: right;
				margin-left: 30px;
			}
		}
	}
}

/*	Config =================================================== */
/*HTML5DOCTOR.COM RESET*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, canvas, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

a:active {
  outline: none; }

/* FONT NORMALISATION*/
body {
  font: 13px sans-serif;
  *font-size: small;
  *font: x-small;
  line-height: 1.22; }

table {
  font-size: inherit;
  font: 100%; }

select, input, textarea {
  font: 99% sans-serif; }

/*CLEARFIX*/
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.clearfix {
  display: inline-block; }

* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

div.clear {
  clear: both;
  width: 100%;
  height: 1px;
  overflow: hidden; }

@font-face {
  font-family: 'agenda';
  src: url("../fonts/agenda-bold-webfont.eot");
  src: url("../fonts/agenda-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/agenda-bold-webfont.woff") format("woff"), url("../fonts/agenda-bold-webfont.ttf") format("truetype"), url("../fonts/agenda-bold-webfont.svg#agendabold") format("svg");
  font-weight: normal;
  font-style: normal; }

header {
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  z-index: 101; }
  #detail header {
    margin-bottom: 0; }
  header #top {
    position: relative;
    display: block;
    max-width: 1180px;
    margin: 0 auto;
    padding: 20px 10px; }
    .lt_ie9 header #top {
      width: 1180px; }
    header #top #logo {
      width: 100%; }
      header #top #logo a {
        margin: 0 auto 20px;
        display: block;
        width: 180px; }
        .lt_ie9 body#detail header #top #logo a {
          margin-bottom: 0; }
        @media screen and (max-width: 480px) {
          body#detail header #top #logo a {
            margin-bottom: 0; } }
        header #top #logo a img {
          width: 100%;
          display: block;
          margin: 0; }
    header #top .phonelink {
      display: block;
      text-align: center;
      margin-bottom: 20px;
      font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 22px;
      font-size: 2.2rem; }
      header #top .phonelink a {
        text-decoration: none; }
      @media screen and (min-width: 768px) {
        header #top .phonelink {
          position: absolute;
          top: 5px;
          right: 0;
          margin: 0; } }
    header #top ul {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      display: block;
      width: 300px; }
      header #top ul li {
        float: left;
        display: inline;
        margin: 0 0 5px;
        font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        font-size: 1.6rem;
        color: #0062ac;
        line-height: 1.1em;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        header #top ul li i {
          color: #ffdf0f; }
    @media screen and (min-width: 480px) {
      header #top #logo a {
        width: 200px; }
      header #top ul {
        width: 100%; }
        header #top ul li {
          width: 50%; } }
    @media screen and (min-width: 600px) {
      header #top #logo a {
        width: 210px; }
      header #top ul {
        width: 580px; }
        header #top ul li {
          font-size: 18px;
          font-size: 1.8rem; } }
    .lt_ie9 header #top {
      width: 730px; }
      .lt_ie9 header #top #logo {
        float: left;
        display: inline;
        width: auto;
        width: 220px; }
        .lt_ie9 header #top #logo a {
          float: left;
          display: inline;
          margin: 0; }
      .lt_ie9 header #top ul {
        float: right;
        display: inline;
        width: 480px; }
        .lt_ie9 header #top ul li {
          width: 300px;
          margin: 5px 0 5px 20px;
          font-size: 18px;
          font-size: 1.8rem; }
    @media screen and (min-width: 768px) {
      header #top {
        width: 730px; }
        header #top #logo {
          float: left;
          display: inline;
          width: auto;
          width: 220px; }
          header #top #logo a {
            float: left;
            display: inline;
            margin: 0; }
        header #top ul {
          float: right;
          display: inline;
          width: 480px; }
          header #top ul li {
            width: 300px;
            margin: 5px 0 5px 20px;
            font-size: 18px;
            font-size: 1.8rem; } }
    @media screen and (min-width: 1024px) {
      header #top {
        width: 960px; }
        header #top ul {
          float: right;
          display: inline;
          width: 640px;
          margin-top: 15px; }
          header #top ul li {
            width: 300px;
            margin: 5px 0 5px 20px;
            font-size: 18px;
            font-size: 1.8rem; } }
    .lt_ie9 header #top {
      width: 1180px; }
      .lt_ie9 header #top ul {
        float: right;
        display: inline;
        width: 860px;
        margin-top: 0; }
        .lt_ie9 header #top ul li {
          width: 380px;
          margin: 5px 0 5px 20px;
          font-size: 20px;
          font-size: 2rem; }
    @media screen and (min-width: 1200px) {
      header #top {
        width: 1180px; }
        header #top ul {
          float: right;
          display: inline;
          width: 860px;
          margin-top: 0; }
          header #top ul li {
            width: 380px;
            margin: 5px 0 5px 20px;
            font-size: 20px;
            font-size: 2rem; } }
    html[lang='fr'] header #top {
      margin-top: 0; }
      html[lang='fr'] header #top li {
        display: block;
        float: none;
        margin-top: 0;
        width: auto; }
        .lt_ie9 html[lang='fr'] header #top li {
          font-size: 14px;
          font-size: 1.4rem; }
        @media screen and (max-width: 480px) {
          html[lang='fr'] header #top li {
            font-size: 14px;
            font-size: 1.4rem; } }
  header #header {
    float: left;
    display: block;
    background: url("../img/bg.png") 50% no-repeat;
    max-height: 400px;
    width: 100%;
    overflow: hidden; }
    .lt_ie9 header #header {
      display: block; }
    @media screen and (min-width: 768px) {
      header #header {
        display: block; } }
    header #header .col-right img {
      width: auto;
      max-height: 200px;
      margin: 25px;
      float: right; }
    header #header img {
      display: block;
      margin: 0;
      width: 100%; }
  header .phone {
    clear: both;
    display: block;
    background: #ffdf0f;
    color: #0062ac;
    font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 28px;
    text-align: center;
    padding: 10px 0; }
    header .phone span {
      display: none; }
      @media screen and (min-width: 600px) {
        header .phone span {
          display: block; } }
      .lt_ie9 header .phone span {
        font-size: 20px;
        font-size: 2rem;
        display: inline;
        position: relative;
        top: -2px;
        left: -10px; }
      @media screen and (min-width: 1024px) {
        header .phone span {
          font-size: 20px;
          font-size: 2rem;
          display: inline;
          position: relative;
          top: -2px;
          left: -10px; } }
    header .phone a {
      color: #0062ac;
      font-size: 28px;
      font-size: 2.8rem;
      text-decoration: none; }

#search {
  display: block;
  clear: left;
  background: #ffdf0f;
  padding: 10px 0; }
  #search.search--sidebar button {
    width: 100px !important; }
    @media screen and (min-width: 768px) {
      #search.search--sidebar button {
        font-size: 20px !important; }
        html[lang='fr'] #search.search--sidebar button {
          font-size: 15px !important; } }
  #search.search--sidebar input#searchinput {
    max-width: calc(100% - 20px); }
    @media screen and (min-width: 600px) {
      #search.search--sidebar input#searchinput {
        font-size: 15px !important; } }
  .lt_ie9 #search {
    padding: 30px 0 10px; }
  @media screen and (min-width: 768px) {
    #search {
      padding: 30px 0 10px; } }
  #search form {
    margin: 0 auto;
    padding: 0;
    display: block;
    position: relative; }
    #search form .tooltip {
      position: relative;
      font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 12px;
      font-size: 1.2rem;
      text-transform: uppercase;
      line-height: 1em;
      color: #ffdf0f;
      background: #004579;
      padding: 5px 10px;
      margin-top: 5px;
      display: none;
      -o-transition: bottom 0.2s ease;
      -moz-transition: bottom 0.2s ease;
      -webkit-transition: bottom 0.2s ease;
      transition: bottom 0.2s ease; }
      #search form .tooltip.show {
        display: block; }
      #search form .tooltip span {
        position: absolute;
        left: 5px;
        top: -20px;
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(252, 255, 0, 0);
        border-bottom-color: #004579;
        border-width: 10px; }
    #search form label {
      display: block;
      font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 14px;
      font-size: 1.4rem;
      text-transform: uppercase;
      line-height: 1em;
      color: #0062ac;
      margin: 0 0 10px; }
    #search form .searchinput {
      position: relative;
      overflow: hidden;
      display: block;
      margin: 0; }
      #search form .searchinput input {
        float: left;
        display: inline;
        outline: none;
        border: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background-clip: padding-box;
        -webkit-appearance: none; }
        #search form .searchinput input.zoeken {
          background: #fff;
          border: 1px solid #b4b4b4;
          outline: none;
          font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
          font-size: 16px;
          font-size: 1.6rem;
          height: 24px;
          width: 70%;
          padding: 2px 2px 2px 5px;
          margin: 0;
          color: #000; }
          #search form .searchinput input.zoeken.placeholder {
            color: #999; }
        .checkout #search form .searchinput input {
          height: auto;
          width: 100% !important; }
      .checkout #search form .searchinput {
        padding-right: 110px;
        margin-bottom: 0; }
        @media screen and (min-width: 600px) {
          .checkout #search form .searchinput {
            padding-right: 140px; } }
        @media screen and (min-width: 768px) {
          .checkout #search form .searchinput {
            padding-right: 160px; } }
        @media screen and (min-width: 1024px) {
          .checkout #search form .searchinput {
            padding-right: 202px; } }
        @media screen and (min-width: 1200px) {
          .checkout #search form .searchinput {
            padding-right: 225px; } }
      #search form .searchinput button {
        position: absolute;
        right: 0;
        top: 0;
        background: #0062ac;
        color: #fff !important;
        border: 0;
        width: 23%;
        height: 30px;
        border-bottom: 3px solid #004579;
        outline: 0;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        cursor: pointer;
        color: #999;
        font-size: 12px;
        font-size: 1.2rem; }
        html[lang='fr'] #search form .searchinput button {
          width: 27%; }
        #search form .searchinput button:hover, #search form .searchinput button:focus {
          color: #fff !important;
          background: #005393 !important;
          border-color: #003660; }
  @media screen and (min-width: 480px) {
    #search form label {
      font-size: 16px;
      font-size: 1.6rem; }
    #search form .tooltip {
      font-size: 14px;
      font-size: 1.4rem; }
      #search form .tooltip span {
        left: 10px; }
    #search form .searchinput input.zoeken {
      width: 260px;
      font-size: 16px;
      font-size: 1.6rem; }
    #search form .searchinput button {
      width: 90px;
      height: 30px;
      font-size: 18px;
      font-size: 1.8rem; } }
  @media screen and (min-width: 600px) {
    #search form label {
      font-size: 18px;
      font-size: 1.8rem; }
    #search form .tooltip {
      font-size: 16px;
      font-size: 1.6rem; }
    #search form .searchinput input.zoeken {
      width: 430px;
      font-size: 16px;
      font-size: 1.6rem; }
    #search form .searchinput button {
      width: 120px;
      height: 30px;
      font-size: 20px;
      font-size: 2rem; } }
  @media screen and (min-width: 768px) {
    #search form label {
      font-size: 20px;
      font-size: 2rem; }
    #search form .tooltip {
      font-size: 18px;
      font-size: 1.8rem; }
      #search form .tooltip span {
        top: -20px;
        border-width: 10px; }
    #search form .searchinput {
      margin-bottom: 3px; }
      #search form .searchinput input.zoeken {
        width: 560px;
        font-size: 16px;
        font-size: 1.6rem;
        padding: 6px 2px 6px 5px; }
      #search form .searchinput button {
        width: 140px;
        height: 38px;
        border-bottom-width: 5px;
        font-size: 24px;
        font-size: 2.4rem; } }
  @media screen and (min-width: 1024px) {
    #search form label {
      font-size: 22px;
      font-size: 2.2rem; }
    #search form .tooltip {
      font-size: 20px;
      font-size: 2rem; }
      #search form .tooltip span {
        top: -24px;
        border-width: 12px; }
    #search form .searchinput {
      margin-bottom: 5px; }
      #search form .searchinput input.zoeken {
        width: 746px;
        font-size: 18px;
        font-size: 1.8rem;
        padding: 8px 2px 8px 10px; }
      #search form .searchinput button {
        width: 180px;
        height: 42px;
        border-bottom-width: 6px;
        font-size: 28px;
        font-size: 2.8rem; } }
  .lt_ie9 #search form label {
    font-size: 24px;
    font-size: 2.4rem; }
  .lt_ie9 #search form .tooltip {
    font-size: 22px;
    font-size: 2.2rem;
    width: 940px;
    margin-top: 0; }
    .lt_ie9 #search form .tooltip span {
      top: -30px;
      border-width: 15px; }
  .lt_ie9 #search form .searchinput {
    margin-bottom: 10px; }
    .lt_ie9 #search form .searchinput input.zoeken {
      width: 944px;
      font-size: 20px;
      font-size: 2rem;
      padding: 10px 2px 10px 12px; }
    .lt_ie9 #search form .searchinput button {
      width: 200px;
      height: 46px;
      border-bottom-width: 8px;
      font-size: 32px;
      font-size: 3.2rem; }
  @media screen and (min-width: 1200px) {
    #search form label {
      font-size: 24px;
      font-size: 2.4rem; }
    #search form .tooltip {
      font-size: 22px;
      font-size: 2.2rem;
      width: 940px;
      margin-top: 0; }
      #search form .tooltip span {
        top: -30px;
        border-width: 15px; }
    #search form .searchinput {
      margin-bottom: 10px; }
      #search form .searchinput input.zoeken {
        width: 944px;
        font-size: 20px;
        font-size: 2rem;
        padding: 10px 2px 10px 12px; }
      #search form .searchinput button {
        width: 200px;
        height: 46px;
        border-bottom-width: 8px;
        font-size: 32px;
        font-size: 3.2rem; } }

#mapheader {
  display: none;
  background: #ffdf0f;
  color: #fff;
  padding: 0;
  font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1em; }
  #mapheader .container {
    background: #0062ac;
    padding: 5px 0 3px; }
  #mapheader div {
    padding: 0 10px; }
  #mapheader span {
    color: #ffdf0f;
    display: block; }
    #mapheader span a {
      color: #ffdf0f;
      font-size: 12px;
      font-size: 1.2rem; }
      #mapheader span a:hover {
        text-decoration: none; }
  #mapheader.show {
    display: block; }
  @media screen and (min-width: 600px) {
    #mapheader {
      font-size: 16px;
      font-size: 1.6rem; }
      #mapheader span {
        display: inline; } }
  @media screen and (min-width: 768px) {
    #mapheader {
      padding: 0 0 12px;
      font-size: 18px;
      font-size: 1.8rem; }
      #mapheader span a {
        font-size: 14px;
        font-size: 1.4rem; } }
  @media screen and (min-width: 1024px) {
    #mapheader {
      padding: 0 0 15px;
      font-size: 20px;
      font-size: 2rem; } }
  .lt_ie9 #mapheader {
    padding: 0 0 20px;
    font-size: 22px;
    font-size: 2.2rem; }
    .lt_ie9 #mapheader span {
      display: inline; }
      .lt_ie9 #mapheader span a {
        font-size: 14px;
        font-size: 1.4rem; }
  @media screen and (min-width: 1200px) {
    #mapheader {
      padding: 0 0 20px;
      font-size: 22px;
      font-size: 2.2rem; }
      #mapheader span {
        display: inline; }
        #mapheader span a {
          font-size: 14px;
          font-size: 1.4rem; } }

#mapfooter {
  clear: both;
  display: none;
  background: #0062ac;
  color: #ffdf0f;
  line-height: 1.1em;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  -webkit-box-shadow: inset 0 1px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 1px -3px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px -3px rgba(0, 0, 0, 0.1); }
  #mapfooter.show {
    display: block; }
  .lt_ie9 #mapfooter {
    line-height: 1.6em; }
  @media screen and (min-width: 768px) {
    #mapfooter {
      line-height: 1.6em; } }

#map {
  border-top: 10px solid #0062ac;
  float: left;
  height: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  -o-transition: height 0.2s ease;
  -moz-transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
  transition: height 0.2s ease; }
  .lt_ie9 #map {
    border-top: 10px solid #dbbe00; }
  @media screen and (min-width: 768px) {
    #map {
      border-top: 10px solid #dbbe00; } }
  .ie7 #map {
    display: none; }
  #map.show {
    display: block;
    height: auto; }
    .ie7 #map.show {
      display: block; }
  .checkout #map {
    height: auto; }
  #map #map_selected {
    padding: 20px;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.45);
    position: relative;
    left: 0;
    width: auto;
    margin-left: 0;
    top: 0;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 20px; }
    @media screen and (min-width: 768px) {
      #map #map_selected {
        width: 320px;
        min-height: 150px;
        margin-left: 24px;
        left: 50%;
        top: 20px;
        position: absolute; } }
    @media screen and (min-width: 1024px) {
      #map #map_selected {
        width: 320px;
        min-height: 150px;
        margin-left: 152px; } }
    @media screen and (min-width: 1200px) {
      #map #map_selected {
        width: 320px;
        min-height: 150px;
        margin-left: 240px; } }
    #map #map_selected .location {
      padding-bottom: 10px;
      background: url(../img/marker_small.png) left top no-repeat; }
      #map #map_selected .location .location__detail__data {
        display: none; }
      @media screen and (min-width: 768px) {
        #map #map_selected .location:nth-child(3) {
          display: block; }
        #map #map_selected .location:nth-child(5) {
          display: none; } }
      @media screen and (min-width: 1024px) {
        #map #map_selected .location:nth-child(1) .location__detail__data {
          display: block; }
        #map #map_selected .location:nth-child(4) {
          display: block; } }
      @media screen and (min-width: 1200px) {
        #map #map_selected .location:nth-child(5) {
          display: block; } }
      #map #map_selected .location .location__detail {
        margin-left: 40px; }
        #map #map_selected .location .location__detail strong {
          display: block;
          margin-bottom: 5px;
          padding-top: 5px;
          font-size: 16px;
          font-size: 1.6rem;
          cursor: pointer; }
        #map #map_selected .location .location__detail a.aanbod {
          display: inline-block;
          background-color: #0062ac;
          color: white;
          text-decoration: none;
          padding: 2px 10px;
          font-weight: bold;
          text-transform: uppercase; }
          #map #map_selected .location .location__detail a.aanbod:hover {
            background-color: #004579; }
        #map #map_selected .location .location__detail span {
          display: block; }
  #map #map_wrapper {
    width: 100%;
    height: 240px; }
    #map #map_wrapper #map_canvas {
      width: 100%;
      height: 240px; }
  #map .infoBox {
    background: #fff;
    min-width: 220px;
    height: 120px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); }
    #map .infoBox img {
      position: absolute !important;
      right: 2px !important;
      top: 2px !important; }
    #map .infoBox div {
      padding: 5px 10px 0;
      height: 100px; }
      #map .infoBox div strong, #map .infoBox div span, #map .infoBox div a {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 12px;
        font-size: 1.2rem;
        line-height: 17px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      #map .infoBox div strong {
        line-height: 18px; }
      #map .infoBox div a.aanbod {
        display: inline;
        background: #0062ac;
        color: #fff;
        margin: 0;
        padding: 3px 5px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif; }
        #map .infoBox div a.aanbod:hover, #map .infoBox div a.aanbod:active {
          background: #004579; }
    #map .infoBox figure {
      float: left;
      display: none;
      margin: 0 10px 0 0; }
      #map .infoBox figure img {
        position: static !important;
        display: block;
        height: 120px;
        width: 120px; }
  @media screen and (min-width: 480px) {
    #map #map_wrapper {
      height: 180px; }
      #map #map_wrapper #map_canvas {
        height: 180px; } }
  @media screen and (min-width: 600px) {
    #map #map_wrapper {
      height: 300px; }
      #map #map_wrapper #map_canvas {
        height: 300px; } }
  @media screen and (min-width: 1024px) {
    #map.show {
      height: 360px; }
    #map #map_wrapper {
      height: 360px; }
      #map #map_wrapper #map_canvas {
        height: 360px; }
    #map .infoBox {
      min-width: 400px; }
      #map .infoBox div strong {
        font-size: 14px;
        font-size: 1.4rem; }
      #map .infoBox div span, #map .infoBox div a {
        font-size: 12px;
        font-size: 1.2rem; }
      #map .infoBox figure {
        display: inline; } }
  .lt_ie9 #map.show {
    height: 400px; }
  .lt_ie9 #map #map_wrapper {
    height: 400px; }
    .lt_ie9 #map #map_wrapper #map_canvas {
      height: 400px; }
  .lt_ie9 #map .infoBox {
    min-width: 400px; }
    .lt_ie9 #map .infoBox div strong {
      font-size: 14px;
      font-size: 1.4rem; }
    .lt_ie9 #map .infoBox div span, .lt_ie9 #map .infoBox div a {
      font-size: 12px;
      font-size: 1.2rem; }
    .lt_ie9 #map .infoBox figure {
      display: inline; }
  @media screen and (min-width: 1200px) {
    #map.show {
      height: 400px; }
    #map #map_wrapper {
      height: 400px; }
      #map #map_wrapper #map_canvas {
        height: 400px; }
    #map .infoBox {
      min-width: 400px; }
      #map .infoBox div strong {
        font-size: 14px;
        font-size: 1.4rem; }
      #map .infoBox div span, #map .infoBox div a {
        font-size: 12px;
        font-size: 1.2rem; }
      #map .infoBox figure {
        display: inline; } }

.location-list-big {
  display: none;
  padding: 0 0 20px 0;
  overflow: hidden; }
  .location-list-big .location {
    margin: 20px 0 0;
    width: 100%;
    float: left; }
    .lt_ie9 .location-list-big .location {
      width: 50%; }
    @media screen and (min-width: 768px) {
      .location-list-big .location {
        width: 50%; } }
    .location-list-big .location .location__detail {
      margin: 0;
      padding: 10px;
      background: #f0f0f0;
      cursor: pointer;
      overflow: hidden; }
      .lt_ie9 .location-list-big .location .location__detail {
        margin: 0 10px 0; }
      @media screen and (min-width: 768px) {
        .location-list-big .location .location__detail {
          margin: 0 10px 0; } }
      .location-list-big .location .location__detail:hover {
        background: #d7d7d7; }
      .location-list-big .location .location__detail.current {
        background: #d7d7d7; }
    .location-list-big .location span {
      display: inline-block;
      font-size: 12px;
      font-size: 1.2rem;
      margin-right: 5px; }
      .location-list-big .location span span {
        margin-right: 0; }
    .location-list-big .location .schedule {
      display: none; }
    .location-list-big .location a {
      display: block; }
    .lt_ie9 .location-list-big .location a {
      float: right;
      margin-left: 30px; }
    @media screen and (min-width: 1024px) {
      .location-list-big .location a {
        float: right;
        margin-left: 30px; } }

html {
  font-size: 62.5%;
  height: 100%;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  color: #010101;
  background-color: whitesmoke;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.6em;
  font-size: 14px;
  font-size: 1.4rem; }
  .lt_ie9 body {
    font-size: 16px;
    font-size: 1.6rem; }
  @media screen and (min-width: 768px) {
    body {
      font-size: 16px;
      font-size: 1.6rem; } }

#site {
  background-color: #f0f0f0;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  overflow: hidden; }
  #detail #site {
    background: #fff; }
  .lt_ie9 #site {
    min-width: 1240px; }

#detail main {
  border-bottom: 2px solid #d7d7d7; }

@media screen and (min-width: 600px) {
  #subpage main {
    margin-bottom: 20px; } }

.lt_ie9 #subpage main {
  float: left;
  display: inline;
  width: 600px;
  margin: 20px 0 0; }

@media screen and (min-width: 1024px) {
  #subpage main {
    float: left;
    display: inline;
    width: 600px;
    margin: 20px 0 0; } }

.lt_ie9 #subpage main {
  width: 700px; }

@media screen and (min-width: 1200px) {
  #subpage main {
    width: 700px; } }

#subpage main.fullwidth {
  width: 100% !important; }

@media screen and (min-width: 600px) {
  #subpage aside {
    width: 280px;
    margin-bottom: 20px; }
    #subpage aside#first {
      float: left;
      display: inline; }
    #subpage aside#second {
      float: right;
      display: inline; } }

@media screen and (min-width: 768px) {
  #subpage aside {
    width: 355px; } }

.lt_ie9 #subpage aside {
  float: right !important;
  clear: right;
  width: 306px;
  margin-right: 2px;
  margin: 20px 0 0; }
  .lt_ie9 #subpage aside section {
    position: relative;
    top: 12px; }

@media screen and (min-width: 1024px) {
  #subpage aside {
    float: right !important;
    clear: right;
    width: 306px;
    margin-right: 2px;
    margin: 20px 0 0; }
    #subpage aside section {
      position: relative;
      top: 12px; } }

.lt_ie9 #subpage aside {
  width: 380px;
  margin-right: 0; }

@media screen and (min-width: 1200px) {
  #subpage aside {
    width: 380px;
    margin-right: 0; } }

.container, #container {
  margin: 0 10px; }
  .container .col, #container .col {
    float: left;
    display: inline;
    margin: 0 10px; }
  #header .container.cols2 .col, #header #container.cols2 .col {
    display: none; }
    #header .container.cols2 .col.first, #header #container.cols2 .col.first {
      display: inline-block;
      width: auto; }
      #header .container.cols2 .col.first section *, #header #container.cols2 .col.first section * {
        display: none; }
      #header .container.cols2 .col.first section h1, #header #container.cols2 .col.first section h1 {
        display: block; }
  .lt_ie9 .container, .lt_ie9 #container {
    width: 380px;
    margin: 0 auto; }
  @media screen and (min-width: 480px) {
    .container, #container {
      width: 380px;
      margin: 0 auto; } }
  @media screen and (min-width: 600px) {
    .container, #container {
      width: 580px; }
      #header .container.cols2 .col.first section *, #header #container.cols2 .col.first section * {
        display: inline-block; } }
  @media screen and (min-width: 768px) {
    .container, #container {
      width: 730px;
      margin: 0 auto; }
      .container.cols1, .container.cols2, .container.cols3, #container.cols1, #container.cols2, #container.cols3 {
        width: 750px; }
      .container.cols1 .col, #container.cols1 .col {
        width: 730px; }
      #header .container.cols2 .col, #header #container.cols2 .col {
        display: none; }
        #header .container.cols2 .col.first, #header #container.cols2 .col.first {
          display: block;
          width: 730px; }
      footer .container.cols2, footer #container.cols2 {
        margin-bottom: 20px; }
      .container.cols2 .col, #container.cols2 .col {
        width: 355px; }
      .container.cols3 .col, #container.cols3 .col {
        width: 230px; } }
  @media screen and (min-width: 1024px) {
    .container, #container {
      width: 960px;
      margin: 0 auto; }
      .container.cols1, .container.cols2, .container.cols3, #container.cols1, #container.cols2, #container.cols3 {
        width: 980px; }
      .container.cols1 .col, #container.cols1 .col {
        width: 960px; }
      #header .container.cols2 .col, #header #container.cols2 .col {
        display: inline; }
        #header .container.cols2 .col.first, #header #container.cols2 .col.first {
          display: inline;
          width: 450px; }
      .container.cols2 .col, #container.cols2 .col {
        width: 470px; }
        footer .container.cols2 .col, footer #container.cols2 .col {
          width: 450px; }
          footer .container.cols2 .col.first, footer #container.cols2 .col.first {
            margin-right: 50px; }
      .container.cols3 .col, #container.cols3 .col {
        width: 306px; } }
  .lt_ie9 .container, .lt_ie9 #container {
    width: 1180px;
    margin: 0 auto; }
    .lt_ie9 .container.cols1, .lt_ie9 .container.cols2, .lt_ie9 .container.cols3, .lt_ie9 #container.cols1, .lt_ie9 #container.cols2, .lt_ie9 #container.cols3 {
      width: 1200px; }
    .lt_ie9 .container.cols1 .col, .lt_ie9 #container.cols1 .col {
      width: 1180px; }
    #header .lt_ie9 .container.cols2 .col, #header .lt_ie9 #container.cols2 .col {
      display: inline; }
      #header .lt_ie9 .container.cols2 .col.first, #header .lt_ie9 #container.cols2 .col.first {
        display: inline;
        width: 540px; }
    footer .lt_ie9 .container.cols2, footer .lt_ie9 #container.cols2 {
      margin-bottom: 40px; }
    .lt_ie9 .container.cols2 .col, .lt_ie9 #container.cols2 .col {
      width: 580px; }
      footer .lt_ie9 .container.cols2 .col, footer .lt_ie9 #container.cols2 .col {
        width: 540px; }
        footer .lt_ie9 .container.cols2 .col.first, footer .lt_ie9 #container.cols2 .col.first {
          margin-right: 90px; }
    .lt_ie9 .container.cols3 .col, .lt_ie9 #container.cols3 .col {
      width: 380px; }
  @media screen and (min-width: 1200px) {
    .container, #container {
      width: 1180px;
      margin: 0 auto; }
      .container.cols1, .container.cols2, .container.cols3, #container.cols1, #container.cols2, #container.cols3 {
        width: 1200px; }
      .container.cols1 .col, #container.cols1 .col {
        width: 1180px; }
      #header .container.cols2 .col, #header #container.cols2 .col {
        display: inline; }
        #header .container.cols2 .col.first, #header #container.cols2 .col.first {
          display: inline;
          width: 540px; }
      footer .container.cols2, footer #container.cols2 {
        margin-bottom: 40px; }
      .container.cols2 .col, #container.cols2 .col {
        width: 580px; }
        footer .container.cols2 .col, footer #container.cols2 .col {
          width: 540px; }
          footer .container.cols2 .col.first, footer #container.cols2 .col.first {
            margin-right: 90px; }
      .container.cols3 .col, #container.cols3 .col {
        width: 380px; } }

.lt_ie9 #subpage #container {
  margin-bottom: 20px; }

@media screen and (min-width: 1024px) {
  #subpage #container {
    margin-bottom: 20px; } }

.lt_ie9 footer .container.cols2 .col {
  width: 540px; }
  .lt_ie9 footer .container.cols2 .col.first {
    margin-right: 90px; }

.col-box {
  *zoom: 1; }

.col-box:before, .col-box:after {
  content: "";
  display: table; }

.col-box:after {
  clear: both; }

@media screen and (min-width: 600px) {
  .col-box__label {
    float: left;
    display: inline;
    width: 220px; } }

@media screen and (min-width: 768px) {
  .col-box__label {
    float: none;
    display: block;
    width: 100%; } }

@media screen and (min-width: 1024px) {
  .col-box__label {
    float: left;
    display: inline;
    width: 220px; } }

@media screen and (min-width: 1200px) {
  .col-box__label {
    width: 265px; } }

.col-box__label p {
  margin-bottom: 0; }

.col-box__label figure {
  max-width: 100%;
  margin-top: 10px;
  margin-right: 20px; }

@media screen and (min-width: 600px) {
  .col-box__field {
    float: left;
    display: inline;
    width: calc(100% - 220px); } }

@media screen and (min-width: 768px) {
  .col-box__field {
    float: none;
    display: block;
    width: 100%; } }

@media screen and (min-width: 1024px) {
  .col-box__field {
    float: left;
    display: inline;
    width: calc(100% - 220px); } }

@media screen and (min-width: 1200px) {
  .col-box__field {
    width: calc(100% - 265px); } }

.col-box--rentalpoint {
  padding-top: 20px;
  position: relative; }
  @media screen and (min-width: 600px) {
    .col-box--rentalpoint {
      min-height: 90px; } }
  .col-box--rentalpoint figure {
    display: none; }
    @media screen and (min-width: 600px) {
      .col-box--rentalpoint figure {
        position: absolute;
        left: 0;
        top: 20px;
        max-width: 100px !important;
        margin: 0;
        display: block; }
        .col-box--rentalpoint figure img {
          max-height: 90px; } }
  @media screen and (min-width: 600px) {
    .col-box--rentalpoint p {
      padding-left: 110px; } }
  .col-box--rentalpoint td {
    background: #fff; }

.new--price {
  padding-top: 20px;
  margin-bottom: 35px; }

footer {
  clear: both;
  background: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  padding-top: 20px; }
  .lt_ie9 footer {
    padding-top: 40px; }
  @media screen and (min-width: 1200px) {
    footer {
      padding-top: 40px; } }
  footer .grijs {
    background: #fff08f;
    padding-top: 20px;
    margin-bottom: 20px; }
    .lt_ie9 footer .grijs {
      padding: 40px 0 20px;
      margin-bottom: 40px; }
    @media screen and (min-width: 1200px) {
      footer .grijs {
        padding: 40px 0 20px;
        margin-bottom: 40px; } }
  footer .container.cols1, footer .container.cols2, footer .container.cols3, footer .grijs {
    display: none; }
    .lt_ie9 footer .container.cols1, .lt_ie9 footer .container.cols2, .lt_ie9 footer .container.cols3, .lt_ie9 footer .grijs {
      display: block; }
    @media screen and (min-width: 768px) {
      footer .container.cols1, footer .container.cols2, footer .container.cols3, footer .grijs {
        display: block; } }
  footer .menu {
    font-size: 14px;
    font-size: 1.4rem;
    list-style: none;
    margin: 0 0 20px;
    padding: 0; }
    footer .menu li {
      margin: 0 0 20px; }
      footer .menu li a {
        font-weight: bold;
        color: #666;
        text-decoration: none; }
        footer .menu li a:hover {
          text-decoration: underline;
          color: #0062ac; }
      footer .menu li ul {
        list-style: none; }
        footer .menu li ul li {
          margin: 0; }
          footer .menu li ul li a {
            font-weight: normal;
            color: #999; }
  footer #footermenu {
    border-top: 0;
    margin: 0 10px 20px;
    color: #666;
    font-size: 12px;
    font-size: 1.2rem; }
    footer #footermenu ul {
      list-style: none; }
      footer #footermenu ul li {
        float: left;
        display: inline;
        margin: 0 15px 0 0; }
        footer #footermenu ul li.item1 {
          font-weight: bold; }
        footer #footermenu ul li.item2, footer #footermenu ul li.item3, footer #footermenu ul li.item4, footer #footermenu ul li.item5, footer #footermenu ul li.item6 {
          clear: left; }
        footer #footermenu ul li a {
          color: #666; }
          footer #footermenu ul li a:hover, footer #footermenu ul li a:active {
            color: #0062ac; }
    @media screen and (min-width: 480px) {
      footer #footermenu ul {
        display: block;
        width: 380px;
        margin: 0 auto; } }
    @media screen and (min-width: 600px) {
      footer #footermenu ul {
        width: 580px; } }
    .lt_ie9 footer #footermenu {
      margin: 0 auto;
      padding: 10px 0;
      width: 730px;
      border-top: 2px solid #eee; }
      .lt_ie9 footer #footermenu ul {
        width: auto; }
        .lt_ie9 footer #footermenu ul li {
          clear: none !important; }
          .lt_ie9 footer #footermenu ul li.item1 {
            font-weight: normal; }
    @media screen and (min-width: 768px) {
      footer #footermenu {
        margin: 0 auto;
        padding: 10px 0;
        width: 730px;
        border-top: 2px solid #eee; }
        footer #footermenu ul {
          width: auto; }
          footer #footermenu ul li {
            clear: none !important; }
            footer #footermenu ul li.item1 {
              font-weight: normal; } }
    @media screen and (min-width: 1024px) {
      footer #footermenu {
        width: 960px; } }
    .lt_ie9 footer #footermenu {
      width: 1180px; }
    @media screen and (min-width: 1200px) {
      footer #footermenu {
        width: 1180px; } }

.fullwidth > *:not(#catalogus) {
  max-width: 700px; }

h1, h2, h3, h4, h5 {
  font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1em;
  font-weight: normal;
  color: #0062ac;
  margin-bottom: .2em; }
  h1 a, h2 a, h3 a, h4 a, h5 a {
    text-decoration: none; }
  h1.black, h2.black, h3.black, h4.black, h5.black {
    color: #000; }
  h1.title--new, h2.title--new, h3.title--new, h4.title--new, h5.title--new {
    margin-bottom: 20px; }

h1, h2.titel, h3.titel, .pagetitle h2 {
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 20px; }
  @media screen and (min-width: 600px) {
    h1, h2.titel, h3.titel, .pagetitle h2 {
      font-size: 28px;
      font-size: 2.8rem; } }
  @media screen and (min-width: 768px) {
    h1, h2.titel, h3.titel, .pagetitle h2 {
      font-size: 36px;
      font-size: 3.6rem; } }
  @media screen and (min-width: 1024px) {
    h1, h2.titel, h3.titel, .pagetitle h2 {
      font-size: 42px;
      font-size: 4.2rem; } }
  .lt_ie9 h1, .lt_ie9 h2.titel, .lt_ie9 h3.titel, .lt_ie9 .pagetitle h2 {
    font-size: 46px;
    font-size: 4.6rem; }
  @media screen and (min-width: 1200px) {
    h1, h2.titel, h3.titel, .pagetitle h2 {
      font-size: 46px;
      font-size: 4.6rem; } }

h2.title--new {
  margin-top: 10px;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 20px; }
  @media screen and (min-width: 600px) {
    h2.title--new {
      font-size: 28px;
      font-size: 2.8rem; } }
  @media screen and (min-width: 768px) {
    h2.title--new {
      font-size: 36px;
      font-size: 3.6rem; } }

aside h3.titel {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (min-width: 600px) {
    aside h3.titel {
      font-size: 20px;
      font-size: 2rem; } }
  @media screen and (min-width: 768px) {
    aside h3.titel {
      font-size: 20px;
      font-size: 2rem; } }
  @media screen and (min-width: 1024px) {
    aside h3.titel {
      font-size: 24px;
      font-size: 2.4rem; } }
  .lt_ie9 aside h3.titel {
    font-size: 32px;
    font-size: 3.2rem; }
  @media screen and (min-width: 1200px) {
    aside h3.titel {
      font-size: 32px;
      font-size: 3.2rem; } }

#home #container main h1,
#home #container main .pagetitle h2 {
  text-align: center; }

#home h1.titel, h2.titel {
  margin-top: 20px; }
  .checkout #home h1.titel, .checkout h2.titel {
    margin-top: 0;
    margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .checkout #home h1.titel, .checkout h2.titel {
      margin-top: 7px;
      font-size: 24px;
      font-size: 2.4rem; } }
  @media screen and (min-width: 1024px) {
    #home h1.titel, h2.titel {
      font-size: 32px;
      font-size: 3.2rem;
      margin-bottom: 10px; }
      .checkout #home h1.titel, .checkout h2.titel {
        font-size: 30px;
        font-size: 3rem;
        margin-top: 7px;
        margin-bottom: 20px; } }
  .lt_ie9 #home h1.titel, .lt_ie9 h2.titel {
    font-size: 46px;
    font-size: 4.6rem;
    margin-bottom: .2em;
    margin-top: 30px; }
    .checkout .lt_ie9 #home h1.titel, .checkout .lt_ie9 h2.titel {
      font-size: 36px;
      font-size: 3.6rem;
      margin-top: 7px;
      margin-bottom: 20px; }
  @media screen and (min-width: 1200px) {
    #home h1.titel, h2.titel {
      font-size: 46px;
      font-size: 4.6rem;
      margin-bottom: .2em;
      margin-top: 30px; }
      .checkout #home h1.titel, .checkout h2.titel {
        font-size: 36px;
        font-size: 3.6rem;
        margin-top: 7px;
        margin-bottom: 20px; } }

h2 {
  font-size: 18px;
  font-size: 1.8rem; }
  @media screen and (min-width: 600px) {
    h2 {
      font-size: 22px;
      font-size: 2.2rem; } }
  .lt_ie9 h2 {
    font-size: 24px;
    font-size: 2.4rem; }
  @media screen and (min-width: 1024px) {
    h2 {
      font-size: 24px;
      font-size: 2.4rem; } }

h3 {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (min-width: 600px) {
    h3 {
      font-size: 20px;
      font-size: 2rem; } }
  .lt_ie9 h3 {
    font-size: 22px;
    font-size: 2.2rem; }
  @media screen and (min-width: 1024px) {
    h3 {
      font-size: 22px;
      font-size: 2.2rem; } }

h4 {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (min-width: 600px) {
    h4 {
      font-size: 18px;
      font-size: 1.8rem; } }
  .lt_ie9 h4 {
    font-size: 20px;
    font-size: 2rem; }
  @media screen and (min-width: 1024px) {
    h4 {
      font-size: 20px;
      font-size: 2rem; } }

h5 {
  font-size: 14px;
  font-size: 1.4rem; }
  @media screen and (min-width: 600px) {
    h5 {
      font-size: 16px;
      font-size: 1.6rem; } }
  .lt_ie9 h5 {
    font-size: 18px;
    font-size: 1.8rem; }
  @media screen and (min-width: 1024px) {
    h5 {
      font-size: 18px;
      font-size: 1.8rem; } }

p {
  margin: 0 0 20px; }
  p.inleiding {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6em;
    color: #666; }
    @media screen and (min-width: 600px) {
      p.inleiding {
        font-size: 16px;
        font-size: 1.6rem; } }
    .lt_ie9 p.inleiding {
      font-size: 18px;
      font-size: 1.8rem; }
    @media screen and (min-width: 768px) {
      p.inleiding {
        font-size: 18px;
        font-size: 1.8rem; } }
  @media screen and (min-width: 1024px) {
    #header p {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 20px;
      margin-bottom: 10px; } }
  #header .lt_ie9 p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6em;
    margin-bottom: 20px; }
  @media screen and (min-width: 1200px) {
    #header p {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.6em;
      margin-bottom: 20px; } }

sub {
  font-size: 80%;
  position: relative;
  top: 3px; }

sup {
  font-size: 80%;
  position: relative;
  top: -3px; }

.faq {
  background: #e1e1e1;
  border: 1px solid #e1e1e1;
  display: block;
  margin-bottom: 20px;
  padding: 5px 10px; }
  .faq.noMargin {
    margin-bottom: 5px; }
  .faq .question {
    position: relative;
    display: block;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.6em;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    text-decoration: none;
    padding-left: 20px;
    color: #0062ac; }
    .faq .question i {
      position: absolute;
      left: -4px;
      top: 0;
      font-size: 22px;
      font-size: 2.2rem; }
    .faq .question i.icon-minus-sign-alt {
      display: none; }
    .faq .question i.icon-plus-sign-alt {
      display: inline-block;
      width: 16px; }
    .faq .question.active {
      color: #004579; }
      .faq .question.active i.icon-minus-sign-alt {
        display: inline-block;
        width: 16px; }
      .faq .question.active i.icon-plus-sign-alt {
        display: none; }
  .faq .answer p {
    margin: 0 0 0 20px; }
  .lt_ie9 .faq {
    padding: 10px; }
    .lt_ie9 .faq .question {
      font-size: 16px;
      font-size: 1.6rem; }
  @media screen and (min-width: 600px) {
    .faq {
      padding: 10px; }
      .faq .question {
        font-size: 16px;
        font-size: 1.6rem; } }

.opsomming {
  margin: 0 0 20px 24px; }
  .opsomming li {
    color: #414141; }
    .opsomming li span {
      color: #010101; }
  .opsomming.articles, .opsomming.calendar {
    list-style: none;
    margin: 0 0 20px;
    border-top: 1px solid #eee;
    line-height: 1.1em; }
    .opsomming.articles li, .opsomming.calendar li {
      border-bottom: 1px solid #eee; }
      .opsomming.articles li a, .opsomming.calendar li a {
        display: block;
        padding: 5px 0;
        text-decoration: none; }
        .opsomming.articles li a span.titel, .opsomming.calendar li a span.titel {
          color: #0062ac;
          display: block;
          margin-bottom: 5px; }
        .opsomming.articles li a span.date, .opsomming.articles li a span.location, .opsomming.calendar li a span.date, .opsomming.calendar li a span.location {
          display: block;
          color: #999;
          font-size: 12px;
          font-size: 1.2rem; }
        .opsomming.articles li a:hover span.titel, .opsomming.calendar li a:hover span.titel {
          color: #004579; }

.usp {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: block; }
  .lt_ie9 #detail .usp {
    display: none; }
  @media screen and (max-width: 480px) {
    #detail .usp {
      display: none; } }
  .usp li {
    margin: 0 0 5px;
    font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    font-size: 1.6rem;
    color: #0062ac;
    line-height: 1.1em; }
    .product .usp li {
      position: relative;
      padding-left: 28px; }
      .product .usp li i {
        position: absolute;
        left: 0;
        top: 3px; }
  @media screen and (min-width: 768px) {
    .usp li {
      font-size: 18px;
      font-size: 1.8rem; } }
  @media screen and (min-width: 1024px) {
    .usp li {
      font-size: 20px;
      font-size: 2rem; } }
  .lt_ie9 .usp li {
    font-size: 22px;
    font-size: 2.2rem; }
  @media screen and (min-width: 1200px) {
    .usp li {
      font-size: 22px;
      font-size: 2.2rem; } }

.usp--new {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: block; }
  .usp--new li {
    margin: 0 0 15px;
    line-height: 1.1em;
    padding-left: 25px;
    position: relative; }
    .usp--new li i {
      position: absolute;
      left: 0;
      top: -4px;
      font-size: 21px;
      color: #ffdf0f; }

a {
  color: #0062ac; }
  a:hover {
    color: #004579; }

a.zoekWoord {
  text-decoration: none;
  background: #FFF2AF;
  padding: 3px; }

a.btn, button {
  position: relative;
  top: 10px;
  background: #0062ac;
  display: inline-block;
  margin-bottom: 20px;
  color: #fff;
  padding: 8px 10px;
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1.1em;
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 4px solid #004579; }
  a.btn:hover, button:hover {
    top: 13px;
    border-bottom: 1px solid #004579;
    margin-bottom: 23px; }
  a.btn.back, button.back {
    background: #999;
    border-bottom: 4px solid #666; }
    a.btn.back:hover, button.back:hover {
      border-bottom: 1px solid #666; }

figure {
  margin: 0 auto 20px;
  max-width: 320px;
  width: 100% !important;
  clear: both; }
  #header .col figure {
    margin-bottom: 0; }
  .lt_ie9 figure {
    max-width: 100%;
    width: auto !important; }
    .lt_ie9 figure.left {
      float: left;
      display: inline;
      clear: none;
      max-width: 49%;
      margin: 0 2% 20px 0; }
    .lt_ie9 figure.right {
      float: right;
      display: inline;
      clear: none;
      max-width: 49%;
      margin: 0 0 20px 2%; }
    .lt_ie9 figure.noMargin {
      margin: 0 0 20px; }
  @media screen and (min-width: 600px) {
    figure {
      max-width: 100%;
      width: auto !important; }
      figure.left {
        float: left;
        display: inline;
        clear: none;
        max-width: 49%;
        margin: 0 2% 20px 0; }
      figure.right {
        float: right;
        display: inline;
        clear: none;
        max-width: 49%;
        margin: 0 0 20px 2%; }
      figure.noMargin {
        margin: 0 0 20px; } }
  figure img {
    display: block;
    max-width: 100%; }
  figure figcaption {
    display: block;
    margin: 6px auto 0;
    text-align: center;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.6em;
    color: #414141; }

.item1014 figure,
.item1152 figure {
  max-width: auto;
  float: left;
  padding: 10px;
  clear: none; }

.video {
  float: left;
  display: block;
  clear: left;
  width: 100%;
  margin: 0 0 20px; }
  .video .videoWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    margin: 0 auto;
    padding: 0 0 56.25%;
    /* 16/9 ratio*/ }
    .video .videoWrapper iframe, .video .videoWrapper object, .video .videoWrapper embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important; }

table {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  color: #666; }
  table tr td, table tr th {
    padding: 3px 5px;
    border: 1px solid #d5d5d5;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.1em;
    font-weight: normal;
    text-align: left; }
    .lt_ie9 table tr td, .lt_ie9 table tr th {
      padding: 5px;
      font-size: 13px;
      font-size: 1.3rem; }
    @media screen and (min-width: 600px) {
      table tr td, table tr th {
        padding: 5px;
        font-size: 13px;
        font-size: 1.3rem; } }
  table tr th, table tr td.first {
    background-color: #e6e6e6 !important;
    font-weight: bold;
    color: #4d4d4d; }
  table tr.odd td, table tr td.even {
    background-color: #f6f6f6; }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom;
  *vertical-align: baseline; }

#content form {
  float: left;
  display: inline;
  clear: both; }

form {
  margin: 0;
  padding: 0;
  clear: both;
  width: 100%; }

.formulier, .aanvraagformulier {
  clear: both;
  margin-bottom: 20px;
  overflow: hidden;
  background: #fbfbfb;
  border: 1px solid #eee;
  padding: 10px; }
  .formulier .clearfix, .aanvraagformulier .clearfix {
    margin-bottom: 10px;
    zoom: 1; }
    .formulier .clearfix:before, .formulier .clearfix:after, .aanvraagformulier .clearfix:before, .aanvraagformulier .clearfix:after {
      display: table;
      content: "";
      zoom: 1; }
    .formulier .clearfix:after, .aanvraagformulier .clearfix:after {
      clear: both; }
  .formulier label, .aanvraagformulier label {
    float: left;
    display: inline;
    width: 100%;
    padding-top: 0;
    margin-bottom: 3px;
    line-height: 1.1em;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #333;
    text-align: left; }
    .formulier label .required, .aanvraagformulier label .required {
      font-size: 18px;
      font-size: 1.8rem;
      color: #E41B13; }
  .formulier .input, .aanvraagformulier .input {
    clear: left;
    margin-left: 0; }
    .formulier .input .inputs-list, .aanvraagformulier .input .inputs-list {
      margin: 0 0 5px;
      width: 100%; }
      .formulier .input .inputs-list li, .aanvraagformulier .input .inputs-list li {
        display: block;
        padding: 0;
        width: 100%; }
      .formulier .input .inputs-list label, .aanvraagformulier .input .inputs-list label {
        display: block;
        float: none;
        width: auto;
        padding: 0 !important;
        margin-left: 20px;
        line-height: 18px;
        text-align: left !important;
        white-space: normal;
        width: 92% !important; }
      .formulier .input .inputs-list:first-child, .aanvraagformulier .input .inputs-list:first-child {
        padding-top: 6px; }
      .formulier .input .inputs-list input[type=radio], .formulier .input .inputs-list input[type=checkbox], .aanvraagformulier .input .inputs-list input[type=radio], .aanvraagformulier .input .inputs-list input[type=checkbox] {
        margin-bottom: 0;
        margin-left: -20px;
        float: left; }
  .formulier input, .formulier select, .formulier textarea, .aanvraagformulier input, .aanvraagformulier select, .aanvraagformulier textarea {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    line-height: normal;
    width: 97%;
    height: 18px;
    padding: 4px 1%;
    color: #666;
    border: 1px solid #b4b4b4; }
  .formulier input, .formulier textarea, .aanvraagformulier input, .aanvraagformulier textarea {
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
    .formulier input:focus, .formulier textarea:focus, .aanvraagformulier input:focus, .aanvraagformulier textarea:focus {
      outline: 0;
      border-color: #b3b3b3;
      -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
    .formulier input.mini, .formulier textarea.mini, .aanvraagformulier input.mini, .aanvraagformulier textarea.mini {
      width: 50px !important; }
    .formulier input.small, .formulier textarea.small, .aanvraagformulier input.small, .aanvraagformulier textarea.small {
      width: 80px !important; }
    .formulier input.placeholder, .formulier textarea.placeholder, .aanvraagformulier input.placeholder, .aanvraagformulier textarea.placeholder {
      color: #999;
      font-weight: normal; }
  .formulier textarea, .aanvraagformulier textarea {
    height: auto;
    overflow-y: auto;
    resize: vertical;
    width: 97%;
    height: 140px;
    max-height: 400px; }
  .formulier select, .aanvraagformulier select {
    height: 27px;
    *height: auto;
    line-height: 27px;
    *margin-top: 4px;
    padding: initial;
    width: 100%; }
    .formulier select.mini, .aanvraagformulier select.mini {
      width: 60px !important; }
    .formulier select.small, .aanvraagformulier select.small {
      width: 90px !important; }
    .formulier select:focus, .aanvraagformulier select:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: 1px dotted #666; }
  .formulier input[type=checkbox], .formulier input[type=radio], .aanvraagformulier input[type=checkbox], .aanvraagformulier input[type=radio] {
    cursor: pointer;
    width: auto !important;
    height: auto !important;
    padding: 0;
    margin: 3px 0;
    *margin-top: 0;
    line-height: normal;
    border: none; }
  .ie9 .formulier input[type=checkbox], .ie9 .aanvraagformulier input[type=checkbox] {
    position: relative;
    top: -1px; }
  .formulier input[type=file], .aanvraagformulier input[type=file] {
    background-color: #fff;
    padding: initial;
    border: initial;
    line-height: initial;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
    .formulier input[type=file]:focus, .aanvraagformulier input[type=file]:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: 1px dotted #666; }
  .formulier button, .aanvraagformulier button {
    top: 5px;
    cursor: pointer;
    border: 0;
    outline: 0;
    margin: 0 0 10px;
    -webkit-font-smoothing: antialiased;
    font-weight: normal;
    border-bottom: 4px solid #004579; }
    .formulier button:hover, .aanvraagformulier button:hover {
      top: 8px;
      border-bottom: 1px solid #004579;
      margin-bottom: 13px; }
  .formulier .clearfix.error label, .formulier .clearfix.error input, .formulier .clearfix.error textarea, .aanvraagformulier .clearfix.error label, .aanvraagformulier .clearfix.error input, .aanvraagformulier .clearfix.error textarea {
    color: #E41B13; }
  .formulier .clearfix.error input, .formulier .clearfix.error textarea, .formulier .clearfix.error select, .aanvraagformulier .clearfix.error input, .aanvraagformulier .clearfix.error textarea, .aanvraagformulier .clearfix.error select {
    border-color: #E41B13; }
    .formulier .clearfix.error input:focus, .formulier .clearfix.error textarea:focus, .formulier .clearfix.error select:focus, .aanvraagformulier .clearfix.error input:focus, .aanvraagformulier .clearfix.error textarea:focus, .aanvraagformulier .clearfix.error select:focus {
      -webkit-box-shadow: 0 0 6px #f58682;
      -moz-box-shadow: 0 0 6px #f58682;
      box-shadow: 0 0 6px #f58682; }
  @media screen and (min-width: 600px) {
    .formulier label, .aanvraagformulier label {
      font-size: 14px;
      font-size: 1.4rem; }
    main .formulier, main .aanvraagformulier {
      padding: 20px 10px; }
      main .formulier label, main .aanvraagformulier label {
        padding-top: 6px;
        margin-bottom: 0;
        width: 30%;
        text-align: right; }
      main .formulier .input, main .aanvraagformulier .input {
        clear: none;
        margin-left: 32%; }
      main .formulier input, main .aanvraagformulier input {
        width: 70%; }
      main .formulier select, main .aanvraagformulier select {
        width: 72%; }
      main .formulier textarea, main .aanvraagformulier textarea {
        height: 200px;
        width: 90%; }
      main .formulier button, main .aanvraagformulier button {
        margin-left: 32%; } }
  .lt_ie9 main .formulier label, .lt_ie9 main .aanvraagformulier label {
    padding-top: 6px;
    margin-bottom: 0;
    width: 30%;
    text-align: right; }
  .lt_ie9 main .formulier .input, .lt_ie9 main .aanvraagformulier .input {
    clear: none;
    margin-left: 32%; }
  .lt_ie9 main .formulier input, .lt_ie9 main .aanvraagformulier input {
    width: 70%; }
  .lt_ie9 main .formulier select, .lt_ie9 main .aanvraagformulier select {
    width: 72%; }
  .lt_ie9 main .formulier textarea, .lt_ie9 main .aanvraagformulier textarea {
    height: 200px;
    width: 90%; }
  .lt_ie9 main .formulier button, .lt_ie9 main .aanvraagformulier button {
    margin-left: 32%; }

.checkout .aanvraagformulier {
  background: transparent;
  border: 0;
  padding: 0; }
  .checkout .aanvraagformulier label {
    font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
    line-height: 1em;
    font-weight: normal;
    color: #000;
    padding-top: 6px;
    margin-bottom: 4px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-size: 1.6rem; }
    @media screen and (min-width: 600px) {
      .checkout .aanvraagformulier label {
        font-size: 20px;
        font-size: 2rem; } }
    .lt_ie9 .checkout .aanvraagformulier label {
      font-size: 22px;
      font-size: 2.2rem; }
    @media screen and (min-width: 1024px) {
      .checkout .aanvraagformulier label {
        font-size: 22px;
        font-size: 2.2rem; } }
  .checkout .aanvraagformulier .input {
    clear: both;
    margin-left: 0; }
    .checkout .aanvraagformulier .input .inputs-list label {
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 14px;
      font-size: 1.4rem; }
      .checkout .aanvraagformulier .input .inputs-list label a {
        color: #000; }
        .checkout .aanvraagformulier .input .inputs-list label a:hover {
          color: #0062ac; }
  .checkout .aanvraagformulier .error .input .inputs-list label a {
    color: #0062ac; }
  .checkout .aanvraagformulier input {
    width: 97%; }
    @media screen and (min-width: 768px) {
      .checkout .aanvraagformulier input {
        height: 20px; } }
    @media screen and (min-width: 1024px) {
      .checkout .aanvraagformulier input {
        height: 22px; } }
    .lt_ie9 .checkout .aanvraagformulier input {
      height: 27px;
      font-size: 16px;
      font-size: 1.6rem; }
    @media screen and (min-width: 1200px) {
      .checkout .aanvraagformulier input {
        height: 27px;
        font-size: 16px;
        font-size: 1.6rem; } }

:-moz-placeholder {
  color: #999; }

::-moz-placeholder {
  color: #999; }

::-webkit-input-placeholder {
  color: #999; }

#returntime,
#pickuptime {
  padding: 7px; }

.exclzat {
  color: #999;
  font-size: 75%; }

p.datetext {
  border-bottom: 1px solid #eee;
  line-height: 1.25;
  margin-bottom: 0;
  padding: 8px 0; }

p.datetext--first {
  border-top: 1px solid #eee; }

p.datetext--last {
  margin-bottom: 20px; }

#catalogus h2 {
  color: #000;
  position: relative;
  text-align: center;
  top: -15px; }

#catalogus .product {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  margin-bottom: 20px; }
  @media screen and (min-width: 1024px) {
    #catalogus .product {
      padding: 20px;
      flex-wrap: nowrap; } }
  #catalogus .product--onlylimit:before, #catalogus .product--notavailable:before {
    background-color: rgba(255, 255, 255, 0.6);
    bottom: 0;
    content: '';
    cursor: default;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
  #catalogus .product--onlylimit:hover:before, #catalogus .product--notavailable:hover:before {
    display: none; }
  #catalogus .product--onlylimit:hover .product__overlay, #catalogus .product--notavailable:hover .product__overlay {
    display: flex; }
  #catalogus .product > * {
    box-sizing: border-box; }
  #catalogus .product__image {
    flex: 0 0 100%; }
    @media screen and (min-width: 768px) {
      #catalogus .product__image {
        flex: 0 1 25%;
        max-width: 25%; } }
    @media screen and (min-width: 1024px) {
      #catalogus .product__image {
        border-right: 1px solid rgba(153, 153, 153, 0.5);
        flex: 0 1 30%;
        max-width: 30%; } }
    #catalogus .product__image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
      @media screen and (min-width: 768px) {
        #catalogus .product__image img {
          margin: 0; } }
  #catalogus .product__meta {
    flex: 1 0 100%; }
    @media screen and (min-width: 768px) {
      #catalogus .product__meta {
        flex: 0 1 33%;
        max-width: 33%; }
        #catalogus .product__meta--width {
          flex: 0 1 66%;
          max-width: 66%; }
          #catalogus .product__meta--width table {
            float: left;
            width: 49%;
            display: inline; } }
    @media screen and (min-width: 1024px) {
      #catalogus .product__meta {
        flex: 0 1 30%;
        max-width: 30%;
        padding: 0 20px; } }
  #catalogus .product__information {
    flex: 1 0 100%;
    font-size: 90%; }
    #catalogus .product__information--inside-meta .product__price {
      margin-top: 0; }
    @media screen and (min-width: 768px) {
      #catalogus .product__information {
        flex: 0 1 25%;
        max-width: 25%; }
        #catalogus .product__information--inside-meta {
          flex: none;
          float: right;
          display: inline;
          width: 66%;
          max-width: 66%; } }
    @media screen and (min-width: 1024px) {
      #catalogus .product__information {
        padding: 0 20px; }
        #catalogus .product__information--inside-meta {
          flex: none;
          display: block;
          max-width: 100%;
          width: 100%;
          padding: 0 !important; } }
    #catalogus .product__information--inside-meta .product__price {
      margin-bottom: 0; }
    #catalogus .product__information .link {
      text-decoration: none; }
  #catalogus .product__action {
    flex: 1 0 100%; }
    @media screen and (min-width: 1024px) {
      #catalogus .product__action {
        flex: 0 1 25%;
        max-width: 25%;
        padding: 0 20px; }
        #catalogus .product__action--width {
          flex: 0 1 40%;
          max-width: 40%; } }
    #catalogus .product__action .btn {
      display: block; }
      @media screen and (min-width: 1024px) {
        #catalogus .product__action .btn {
          margin-top: 1.8em; }
          #subpage #catalogus .product__action .btn {
            margin-top: 10px; } }
  #catalogus .product__overlay {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.95);
    bottom: 0;
    cursor: default;
    display: none;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; }
  #catalogus .product__title {
    color: #0062ac;
    margin-bottom: 20px;
    text-align: left;
    top: 0; }
  #catalogus .product .table {
    border: 0; }
    #catalogus .product .table tr td {
      border: 0;
      color: #000;
      padding-left: 0; }
      #catalogus .product .table tr td:first-child {
        font-weight: bold; }
  #catalogus .product__price {
    background-color: rgba(255, 223, 15, 0.3);
    border: 1px solid rgba(255, 223, 15, 0.5);
    border-radius: 4px;
    margin-top: 20px;
    padding: 10px; }
  #catalogus .product .price__3-hours {
    color: #0062ac;
    font-size: 115%;
    font-weight: bold; }

.productdetail {
  background: #f0f0f0; }
  .productdetail .product {
    margin: 0 10px;
    padding: 10px 0; }
    .productdetail .product #detailContent {
      display: none; }
    .lt_ie9 .productdetail .product .mobile--hide {
      display: none; }
    @media screen and (max-width: 480px) {
      .productdetail .product .mobile--hide {
        display: none; } }
    .productdetail .product h1 {
      color: #000;
      margin: 0; }
    .productdetail .product h2 {
      color: #000;
      margin-bottom: 20px; }
      .lt_ie9 .productdetail .product h2 {
        margin-bottom: 0; }
      @media screen and (max-width: 480px) {
        .productdetail .product h2 {
          margin-bottom: 0; } }
      .productdetail .product h2 span {
        display: block;
        margin-right: 20px; }
        @media screen and (min-width: 600px) {
          .productdetail .product h2 span {
            display: inline; } }
        @media screen and (min-width: 768px) {
          .productdetail .product h2 span {
            display: block; } }
        .lt_ie9 .productdetail .product h2 span {
          display: inline; }
        @media screen and (min-width: 1024px) {
          .productdetail .product h2 span {
            display: inline; } }
    .productdetail .product h3 {
      color: #000; }
    .productdetail .product #slideshowholder {
      position: relative;
      padding-right: 124px;
      background: #fff; }
      .productdetail .product #slideshowholder.onlyone {
        padding-right: 0; }
    .productdetail .product #slideshow-1 {
      width: 100%;
      max-width: 540px;
      margin: 0 auto 10px; }
      .productdetail .product #slideshow-1 #cycle-1 div, .productdetail .product #slideshow-1 .cycle-slideshow div {
        padding: 10px;
        background: #fff; }
        .productdetail .product #slideshow-1 #cycle-1 div img, .productdetail .product #slideshow-1 .cycle-slideshow div img {
          width: 100%;
          height: auto;
          display: block; }
    .productdetail .product #slideshow-2 {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 100%;
      z-index: 100;
      margin: 0 auto 20px; }
      .productdetail .product #slideshow-2 .cycle-slide img {
        border: 2px solid #e7e7e7; }
      .productdetail .product #slideshow-2 .cycle-slide-active img {
        border: 2px solid #0062ac; }
      .productdetail .product #slideshow-2 img {
        display: block;
        margin: auto;
        max-width: 100px;
        cursor: pointer;
        background: #fff; }
  .productdetail .properties {
    margin-bottom: 20px;
    overflow: hidden;
    font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .productdetail .properties .property {
      position: relative;
      display: block;
      float: left;
      width: 100%;
      text-indent: 35px;
      line-height: 24px;
      margin-bottom: 5px; }
      .lt_ie9 .productdetail .properties .property {
        width: 50%; }
      @media screen and (min-width: 768px) {
        .productdetail .properties .property {
          width: 50%; } }
      .lt_ie9 .productdetail .properties .property {
        width: 33.33%; }
      @media screen and (min-width: 1024px) {
        .productdetail .properties .property {
          width: 33.33%; } }
    .productdetail .properties .icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px; }
      .productdetail .properties .icon--afmeting {
        width: 30px;
        background: url(../img/icon_afmetingen.png) 50% 0 no-repeat; }
      .productdetail .properties .icon--lading {
        width: 30px;
        background: url(../img/icon_belading.png) 50% 0 no-repeat; }
      .productdetail .properties .icon--rijbewijs {
        width: 30px;
        background: url(../img/icon_rijbewijs.png) 50% 0 no-repeat; }
      .productdetail .properties .icon--gewicht {
        width: 30px;
        background: url(../img/icon_gewicht.png) 50% 0 no-repeat; }
      .productdetail .properties .icon--geremd {
        width: 30px;
        background: url(../img/icon_geremd.png) 50% 0 no-repeat; }
  @media screen and (min-width: 600px) {
    .productdetail .product {
      padding-bottom: 20px; } }
  .lt_ie9 .productdetail {
    float: left;
    display: inline;
    width: 50%;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6em; }
    .lt_ie9 .productdetail .product {
      float: right;
      display: inline;
      width: 355px;
      padding-bottom: 40px; }
      .lt_ie9 .productdetail .product #detailContent {
        display: block; }
  @media screen and (min-width: 768px) {
    .productdetail {
      float: left;
      display: inline;
      width: 50%;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.6em; }
      .productdetail .product {
        float: right;
        display: inline;
        width: 355px;
        padding-bottom: 40px; }
        .productdetail .product #detailContent {
          display: block; } }
  @media screen and (min-width: 1024px) {
    .productdetail .product {
      width: 450px;
      margin-right: 30px; } }
  .lt_ie9 .productdetail {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6em; }
    .lt_ie9 .productdetail .product {
      width: 540px;
      margin-right: 50px; }
  @media screen and (min-width: 1200px) {
    .productdetail {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.6em; }
      .productdetail .product {
        width: 540px;
        margin-right: 50px; } }

.wrapper--2 {
  background: #f0f0f0; }

.wrapper .checkout {
  margin: 0 10px;
  padding: 10px 0; }
  .wrapper .checkout .clearfix {
    display: block;
    clear: both; }
    .wrapper .checkout .clearfix .input {
      clear: left;
      float: left;
      margin-left: 0;
      width: 100%; }
  .wrapper .checkout .ophalen .clearfix, .wrapper .checkout .terugbrengen .clearfix {
    margin-bottom: 10px;
    zoom: 1; }
    .wrapper .checkout .ophalen .clearfix:before, .wrapper .checkout .ophalen .clearfix:after, .wrapper .checkout .terugbrengen .clearfix:before, .wrapper .checkout .terugbrengen .clearfix:after {
      display: table;
      content: "";
      zoom: 1; }
    .wrapper .checkout .ophalen .clearfix:after, .wrapper .checkout .terugbrengen .clearfix:after {
      clear: both; }
    .wrapper .checkout .ophalen .clearfix.hidden, .wrapper .checkout .terugbrengen .clearfix.hidden {
      display: none; }
    .wrapper .checkout .ophalen .clearfix.error label, .wrapper .checkout .terugbrengen .clearfix.error label {
      color: #0062ac; }
    .wrapper .checkout .ophalen .clearfix.error input, .wrapper .checkout .terugbrengen .clearfix.error input {
      border: 1px solid #0062ac; }
    .wrapper .checkout .ophalen .clearfix label, .wrapper .checkout .terugbrengen .clearfix label {
      display: block;
      font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
      line-height: 1em;
      font-weight: normal;
      font-size: 16px;
      font-size: 1.6rem;
      color: #000;
      margin-bottom: 5px; }
    .wrapper .checkout .ophalen .clearfix input, .wrapper .checkout .ophalen .clearfix select, .wrapper .checkout .terugbrengen .clearfix input, .wrapper .checkout .terugbrengen .clearfix select {
      border: 1px solid #b4b4b4;
      height: 19px;
      width: 97%;
      padding: 4px 5px;
      font-size: 14px;
      font-size: 1.4rem;
      -o-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); }
      .wrapper .checkout .ophalen .clearfix input:focus, .wrapper .checkout .ophalen .clearfix select:focus, .wrapper .checkout .terugbrengen .clearfix input:focus, .wrapper .checkout .terugbrengen .clearfix select:focus {
        outline: 0;
        border-color: #b3b3b3;
        -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
      .wrapper .checkout .ophalen .clearfix input#pickupdatepicker, .wrapper .checkout .ophalen .clearfix input#returndatepicker, .wrapper .checkout .ophalen .clearfix select#pickupdatepicker, .wrapper .checkout .ophalen .clearfix select#returndatepicker, .wrapper .checkout .terugbrengen .clearfix input#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix input#returndatepicker, .wrapper .checkout .terugbrengen .clearfix select#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix select#returndatepicker {
        float: left;
        display: inline;
        width: 97%;
        background: #fff url("../img/calendar.png") right 50% no-repeat;
        cursor: pointer; }
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          .wrapper .checkout .ophalen .clearfix input#pickupdatepicker, .wrapper .checkout .ophalen .clearfix input#returndatepicker, .wrapper .checkout .ophalen .clearfix select#pickupdatepicker, .wrapper .checkout .ophalen .clearfix select#returndatepicker, .wrapper .checkout .terugbrengen .clearfix input#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix input#returndatepicker, .wrapper .checkout .terugbrengen .clearfix select#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix select#returndatepicker {
            background-image: url("../img/calendar@2x.png");
            background-size: 39px 100px; } }
        .lt_ie9 .wrapper .checkout .ophalen .clearfix input#pickupdatepicker, .lt_ie9 .wrapper .checkout .ophalen .clearfix input#returndatepicker, .lt_ie9 .wrapper .checkout .ophalen .clearfix select#pickupdatepicker, .lt_ie9 .wrapper .checkout .ophalen .clearfix select#returndatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix input#pickupdatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix input#returndatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix select#pickupdatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix select#returndatepicker {
          background: #fff url("../img/calendar.png") right 50% no-repeat;
          background-size: 39px 100px; }
    .wrapper .checkout .ophalen .clearfix select, .wrapper .checkout .terugbrengen .clearfix select {
      height: 27px;
      *height: auto;
      line-height: 27px;
      *margin-top: 4px;
      padding: initial;
      width: 100%; }
    .wrapper .checkout .ophalen .clearfix img.ui-datepicker-trigger, .wrapper .checkout .terugbrengen .clearfix img.ui-datepicker-trigger {
      width: 29px;
      cursor: pointer;
      float: left;
      display: inline;
      display: none !important; }
  .wrapper .checkout .message.error {
    background: #0062ac;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6em;
    color: #fff;
    padding: 5px; }
  .wrapper .checkout .actions {
    position: relative;
    clear: both;
    height: 40px;
    padding-top: 10px;
    margin-bottom: 20px; }
    .wrapper .checkout .actions .btn {
      top: 0;
      width: 45%;
      padding-left: 0;
      padding-right: 0; }
      .wrapper .checkout .actions .btn:hover {
        top: 3px; }
    .wrapper .checkout .actions button {
      float: right;
      display: block;
      top: 0;
      cursor: pointer;
      border: 0;
      outline: 0;
      border-bottom: 4px solid #004579;
      margin: 0;
      -webkit-font-smoothing: antialiased;
      font-weight: normal;
      width: 45%; }
      .wrapper .checkout .actions button:hover {
        top: 3px;
        border-bottom: 1px solid #004579; }
  .wrapper .checkout .col h3 {
    color: #000; }

@media screen and (min-width: 600px) {
  .wrapper .checkout .ophalen, .wrapper .checkout .terugbrengen {
    float: left;
    display: inline;
    width: 45%;
    margin-right: 10%; }
    .wrapper .checkout .ophalen .clearfix label, .wrapper .checkout .terugbrengen .clearfix label {
      font-size: 20px;
      font-size: 2rem; }
  .wrapper .checkout .terugbrengen {
    margin-right: 0; }
  .wrapper .checkout .actions button, .wrapper .checkout .actions .btn {
    width: 45%;
    font-size: 18px;
    font-size: 1.8rem; } }

.lt_ie9 .wrapper {
  float: left;
  display: inline;
  width: 50%; }
  .lt_ie9 .wrapper .checkout {
    float: left;
    display: inline;
    width: 355px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6em; }
    .lt_ie9 .wrapper .checkout--2 {
      float: right; }
    .lt_ie9 .wrapper .checkout .ophalen, .lt_ie9 .wrapper .checkout .terugbrengen {
      width: 100%; }
      .lt_ie9 .wrapper .checkout .ophalen .clearfix label, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix label {
        font-size: 18px;
        font-size: 1.8rem; }

@media screen and (min-width: 768px) {
  .wrapper {
    float: left;
    display: inline;
    width: 50%; }
    .wrapper .checkout {
      float: left;
      display: inline;
      width: 355px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.6em; }
      .wrapper .checkout--2 {
        float: right; }
      .wrapper .checkout .ophalen, .wrapper .checkout .terugbrengen {
        width: 100%; }
        .wrapper .checkout .ophalen .clearfix label, .wrapper .checkout .terugbrengen .clearfix label {
          font-size: 18px;
          font-size: 1.8rem; } }

@media screen and (min-width: 1024px) {
  .wrapper .checkout {
    width: 440px;
    margin-right: 30px;
    margin-left: 20px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6em; }
    .wrapper .checkout .ophalen, .wrapper .checkout .terugbrengen {
      float: left;
      display: inline;
      width: 210px;
      margin-right: 20px; }
      .wrapper .checkout .ophalen .clearfix label, .wrapper .checkout .terugbrengen .clearfix label {
        font-size: 20px;
        font-size: 2rem; }
      .wrapper .checkout .ophalen .clearfix input, .wrapper .checkout .ophalen .clearfix select, .wrapper .checkout .terugbrengen .clearfix input, .wrapper .checkout .terugbrengen .clearfix select {
        font-size: 15px;
        font-size: 1.5rem;
        height: 24px; }
        .wrapper .checkout .ophalen .clearfix input#pickupdatepicker, .wrapper .checkout .ophalen .clearfix input#returndatepicker, .wrapper .checkout .ophalen .clearfix select#pickupdatepicker, .wrapper .checkout .ophalen .clearfix select#returndatepicker, .wrapper .checkout .terugbrengen .clearfix input#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix input#returndatepicker, .wrapper .checkout .terugbrengen .clearfix select#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix select#returndatepicker {
          width: 198px; }
      .wrapper .checkout .ophalen .clearfix select, .wrapper .checkout .terugbrengen .clearfix select {
        height: 34px;
        *height: auto;
        line-height: 34px; }
      .wrapper .checkout .ophalen .clearfix img.ui-datepicker-trigger, .wrapper .checkout .terugbrengen .clearfix img.ui-datepicker-trigger {
        width: 34px; }
    .wrapper .checkout .terugbrengen {
      margin-right: 0; }
    .wrapper .checkout .actions button, .wrapper .checkout .actions .btn {
      width: 210px;
      font-size: 20px;
      font-size: 2rem;
      margin-right: 0; }
    .wrapper .checkout .col {
      float: left;
      display: inline;
      width: 210px;
      margin-right: 20px; }
      .wrapper .checkout .col.last {
        margin-right: 0; } }

.lt_ie9 .wrapper .checkout {
  width: 520px;
  margin-right: 50px;
  margin-left: 30px; }
  .lt_ie9 .wrapper .checkout .ophalen, .lt_ie9 .wrapper .checkout .terugbrengen {
    width: 250px;
    margin-right: 20px; }
    .lt_ie9 .wrapper .checkout .ophalen .clearfix label, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix label {
      font-size: 22px;
      font-size: 2.2rem; }
    .lt_ie9 .wrapper .checkout .ophalen .clearfix input, .lt_ie9 .wrapper .checkout .ophalen .clearfix select, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix input, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix select {
      font-size: 16px;
      font-size: 1.6rem;
      height: 29px;
      padding: 4px 10px; }
      .lt_ie9 .wrapper .checkout .ophalen .clearfix input#pickupdatepicker, .lt_ie9 .wrapper .checkout .ophalen .clearfix input#returndatepicker, .lt_ie9 .wrapper .checkout .ophalen .clearfix select#pickupdatepicker, .lt_ie9 .wrapper .checkout .ophalen .clearfix select#returndatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix input#pickupdatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix input#returndatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix select#pickupdatepicker, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix select#returndatepicker {
        width: 228px; }
    .lt_ie9 .wrapper .checkout .ophalen .clearfix select, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix select {
      height: 39px;
      *height: auto;
      line-height: 39px;
      *margin-top: 4px;
      padding: initial;
      width: 100%; }
    .lt_ie9 .wrapper .checkout .ophalen .clearfix img.ui-datepicker-trigger, .lt_ie9 .wrapper .checkout .terugbrengen .clearfix img.ui-datepicker-trigger {
      width: 39px; }
  .lt_ie9 .wrapper .checkout .terugbrengen {
    margin-right: 0; }
  .lt_ie9 .wrapper .checkout .actions button, .lt_ie9 .wrapper .checkout .actions .btn {
    width: 250px;
    font-size: 24px;
    font-size: 2.4rem;
    margin-right: 0; }
  .lt_ie9 .wrapper .checkout .col {
    float: left;
    display: inline;
    width: 250px;
    margin-right: 20px; }
    .lt_ie9 .wrapper .checkout .col.last {
      margin-right: 0; }

@media screen and (min-width: 1200px) {
  .wrapper .checkout {
    width: 520px;
    margin-right: 50px;
    margin-left: 30px; }
    .wrapper .checkout .ophalen, .wrapper .checkout .terugbrengen {
      width: 250px;
      margin-right: 20px; }
      .wrapper .checkout .ophalen .clearfix label, .wrapper .checkout .terugbrengen .clearfix label {
        font-size: 22px;
        font-size: 2.2rem; }
      .wrapper .checkout .ophalen .clearfix input, .wrapper .checkout .ophalen .clearfix select, .wrapper .checkout .terugbrengen .clearfix input, .wrapper .checkout .terugbrengen .clearfix select {
        font-size: 16px;
        font-size: 1.6rem;
        height: 29px;
        padding: 4px 10px; }
        .wrapper .checkout .ophalen .clearfix input#pickupdatepicker, .wrapper .checkout .ophalen .clearfix input#returndatepicker, .wrapper .checkout .ophalen .clearfix select#pickupdatepicker, .wrapper .checkout .ophalen .clearfix select#returndatepicker, .wrapper .checkout .terugbrengen .clearfix input#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix input#returndatepicker, .wrapper .checkout .terugbrengen .clearfix select#pickupdatepicker, .wrapper .checkout .terugbrengen .clearfix select#returndatepicker {
          width: 228px; }
      .wrapper .checkout .ophalen .clearfix select, .wrapper .checkout .terugbrengen .clearfix select {
        height: 39px;
        *height: auto;
        line-height: 39px;
        *margin-top: 4px;
        padding: initial;
        width: 100%; }
      .wrapper .checkout .ophalen .clearfix img.ui-datepicker-trigger, .wrapper .checkout .terugbrengen .clearfix img.ui-datepicker-trigger {
        width: 39px; }
    .wrapper .checkout .terugbrengen {
      margin-right: 0; }
    .wrapper .checkout .actions button, .wrapper .checkout .actions .btn {
      width: 250px;
      font-size: 24px;
      font-size: 2.4rem;
      margin-right: 0; }
    .wrapper .checkout .col {
      float: left;
      display: inline;
      width: 250px;
      margin-right: 20px; }
      .wrapper .checkout .col.last {
        margin-right: 0; } }

.ie7 .wrapper {
  float: left;
  display: inline;
  width: 49%; }

.lt_ie9 .wrapper select {
  height: 32px !important;
  margin-top: 0 !important;
  line-height: 32px !important; }

.lt_ie9 .wrapper .checkout .clearfix {
  display: block;
  clear: both; }
  .lt_ie9 .wrapper .checkout .clearfix label {
    float: none;
    display: block;
    margin-bottom: 5px;
    text-align: left; }
  .lt_ie9 .wrapper .checkout .clearfix input {
    width: 97%; }

.ie8 .wrapper input {
  line-height: 28px !important; }

.lt_ie9 .wrapper .actions .btn.back {
  float: left; }

.ie9 .wrapper select {
  height: 32px !important;
  padding-top: 8px !important;
  line-height: 32px !important; }

.col--mobiel {
  display: inline;
  width: 100% !important; }
  .col--mobiel p {
    margin-bottom: 10px; }
  .col--mobiel p.last {
    margin-bottom: 0; }
  .lt_ie9 .col--mobiel {
    display: none !important; }
  @media screen and (min-width: 1024px) {
    .col--mobiel {
      display: none !important; } }

.col--prijs {
  display: none; }
  .lt_ie9 .col--prijs {
    display: inline; }
  @media screen and (min-width: 1024px) {
    .col--prijs {
      display: inline; } }

#menubtn {
  position: absolute;
  left: 10px;
  top: 20px;
  z-index: 9999;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #0062ac;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  text-align: center; }
  #menubtn .icon-remove {
    display: none; }
  #menubtn .icon-reorder {
    display: inline; }
  #menubtn.close {
    background: #fff;
    color: #0062ac; }
    #menubtn.close .icon-remove {
      display: inline; }
    #menubtn.close .icon-reorder {
      display: none; }
  .lt_ie9 #menubtn {
    display: none; }
  @media screen and (min-width: 600px) {
    #menubtn {
      display: none; } }

header nav {
  position: absolute;
  left: -100%;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 70px 0 0;
  margin: 0;
  background: #0062ac;
  overflow: hidden;
  display: block;
  -o-transition: left 0.2s ease;
  -moz-transition: left 0.2s ease;
  -webkit-transition: left 0.2s ease;
  transition: left 0.2s ease; }
  header nav.show {
    left: 0; }
  header nav ul {
    margin: 0 auto;
    max-width: 1200px; }
    header nav ul li {
      position: relative;
      float: left;
      zoom: 1;
      margin: 0;
      width: 100%;
      clear: left;
      display: block;
      border-top: 1px solid #004579; }
      .lt_ie9 header nav ul li {
        display: inline; }
      header nav ul li a {
        display: block;
        text-decoration: none;
        color: #fff;
        padding: 0 10px;
        font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 35px; }
        header nav ul li a i {
          display: none !important; }
      header nav ul li.parent a i {
        display: inline !important; }
      header nav ul li.hover a,
      header nav ul li a:hover {
        color: #fff; }
      header nav ul li.selected a {
        background: #005393;
        color: #fff; }
  .lt_ie9 header nav {
    padding: 0;
    width: 100%;
    margin: 0 auto;
    border-top: 0;
    display: block;
    position: static;
    overflow: visible;
    background: #0062ac;
    height: auto !important; }
    #detail .lt_ie9 header nav {
      border-bottom: 1px solid #eee; }
    .lt_ie9 header nav ul {
      display: block;
      margin: 0 auto;
      height: 45px;
      width: 600px; }
      .lt_ie9 header nav ul li {
        clear: none;
        padding: 0;
        margin: 0 10px;
        width: auto;
        border: 0; }
        .lt_ie9 header nav ul li a {
          line-height: 45px;
          font-size: 14px;
          font-size: 1.4rem;
          padding: 0;
          color: #fff; }
        .lt_ie9 header nav ul li.hover a,
        .lt_ie9 header nav ul li a:hover {
          color: #ffdf0f; }
        .lt_ie9 header nav ul li.selected a {
          color: #ffdf0f;
          background: #0062ac; }
  @media screen and (min-width: 600px) {
    header nav {
      padding: 0;
      width: 100%;
      margin: 0 auto;
      border-top: 0;
      display: block;
      position: static;
      overflow: visible;
      background: #0062ac;
      height: auto !important; }
      #detail header nav {
        border-bottom: 1px solid #eee; }
      header nav ul {
        display: block;
        margin: 0 auto;
        height: 45px;
        width: 600px; }
        header nav ul li {
          clear: none;
          padding: 0;
          margin: 0 10px;
          width: auto;
          border: 0; }
          header nav ul li a {
            line-height: 45px;
            font-size: 14px;
            font-size: 1.4rem;
            padding: 0;
            color: #fff; }
          header nav ul li.hover a,
          header nav ul li a:hover {
            color: #ffdf0f; }
          header nav ul li.selected a {
            color: #ffdf0f;
            background: #0062ac; } }
  @media screen and (min-width: 768px) {
    header nav ul {
      width: 750px; }
      header nav ul li {
        margin-right: 10px; }
        header nav ul li a {
          font-size: 16px;
          font-size: 1.6rem; } }
  @media screen and (min-width: 1024px) {
    header nav ul {
      width: 900px;
      padding-right: 80px; }
      header nav ul li {
        margin-right: 20px; } }
  .lt_ie9 header nav ul {
    width: 1200px;
    padding-right: 0; }
    .lt_ie9 header nav ul li {
      margin-right: 20px; }
      .lt_ie9 header nav ul li a {
        font-size: 16px;
        font-size: 1.6rem; }
  @media screen and (min-width: 1200px) {
    header nav ul {
      width: 1200px;
      padding-right: 0; }
      header nav ul li {
        margin-right: 20px; }
        header nav ul li a {
          font-size: 16px;
          font-size: 1.6rem; } }

header nav ul li ul.submenu {
  visibility: visible;
  border-top: 0;
  height: auto;
  margin: 0; }
  header nav ul li ul.submenu li {
    font-weight: normal;
    float: left;
    display: block;
    clear: left;
    margin: 0; }
    header nav ul li ul.submenu li a {
      display: block;
      padding: 5px 10px 5px 20px;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: bold;
      line-height: 1.1em;
      font-size: 13px;
      font-size: 1.3rem;
      color: #eee; }
    header nav ul li ul.submenu li:hover ul {
      visibility: visible; }
    header nav ul li ul.submenu li.selected a {
      color: #fff;
      background: #004579; }

.lt_ie9 header nav ul li ul.submenu {
  position: absolute;
  top: 100%;
  left: -10px;
  z-index: 2;
  width: 200px;
  visibility: hidden;
  border: 1px solid #004579;
  border-top: 0;
  height: auto; }
  .lt_ie9 header nav ul li ul.submenu li {
    font-weight: normal;
    float: left;
    display: block;
    clear: left;
    width: 200px;
    margin: 0;
    border: 0; }
    .lt_ie9 header nav ul li ul.submenu li a {
      display: block;
      padding: 6px 9px;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      line-height: 1.1em;
      font-size: 13px;
      font-size: 1.3rem; }
    .lt_ie9 header nav ul li ul.submenu li:hover ul {
      visibility: visible; }

.lt_ie9 header nav ul li.hover ul li a {
  background: #0062ac;
  color: #fff; }

.lt_ie9 header nav ul li.hover ul li.hover a,
.lt_ie9 header nav ul li:hover ul li a:hover {
  color: #eee; }

.lt_ie9 header nav ul li.hover ul li.selected a {
  background: #0062ac;
  color: #ffdf0f; }

@media screen and (min-width: 600px) {
  header nav ul li ul.submenu {
    position: absolute;
    top: 100%;
    left: -10px;
    z-index: 2;
    width: 200px;
    visibility: hidden;
    border: 1px solid #004579;
    border-top: 0;
    height: auto; }
    header nav ul li ul.submenu li {
      font-weight: normal;
      float: left;
      display: block;
      clear: left;
      width: 200px;
      margin: 0;
      border: 0; }
      header nav ul li ul.submenu li a {
        display: block;
        padding: 6px 9px;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        line-height: 1.1em;
        font-size: 13px;
        font-size: 1.3rem; }
      header nav ul li ul.submenu li:hover ul {
        visibility: visible; }
  header nav ul li.hover ul li a {
    background: #0062ac;
    color: #fff; }
  header nav ul li.hover ul li.hover a,
  header nav ul li:hover ul li a:hover {
    color: #eee; }
  header nav ul li.hover ul li.selected a {
    background: #0062ac;
    color: #ffdf0f; } }

.lang {
  position: absolute;
  right: 10px;
  top: 15px;
  max-width: 42px; }
  @media screen and (min-width: 480px) {
    .lang {
      max-width: 94px; } }
  @media screen and (min-width: 768px) {
    .lang {
      top: 40px;
      right: 0; } }
  @media screen and (min-width: 1024px) {
    .lang {
      top: auto;
      right: 0;
      bottom: -42px; } }
  .lang a {
    float: left;
    display: inline;
    height: 21px;
    width: 32px;
    text-decoration: none;
    overflow: hidden;
    text-indent: -999px;
    padding: 5px;
    margin-bottom: 5px; }
    @media screen and (min-width: 480px) {
      .lang a {
        margin-left: 5px; } }
  .lang__nl {
    background: url("../img/nl.gif") 50% no-repeat; }
    .lang__nl--selected {
      background-color: #ffdf0f; }
  .lang__fr {
    background: url("../img/fr.gif") 50% no-repeat; }
    .lang__fr--selected {
      background-color: #ffdf0f; }

.translation__not-found {
  background-color: #E41B13;
  color: #fff;
  display: inline-block;
  padding: 1px 2px; }

#stappen {
  display: none;
  list-style: none;
  margin: 20px 0; }
  #stappen li {
    float: left;
    display: inline;
    width: 25%; }
    #stappen li a, #stappen li span {
      width: 90%;
      display: block;
      background: #f0f0f0;
      text-align: left;
      padding: 10px 0;
      color: #999;
      font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
      line-height: 1em;
      font-weight: normal;
      font-size: 16px;
      font-size: 1.6rem;
      text-decoration: none; }
    #stappen li.finished a {
      color: #000;
      position: relative; }
      #stappen li.finished a span {
        color: #000;
        display: inline;
        border-bottom: 2px solid #b4b4b4;
        padding: 0; }
      #stappen li.finished a:hover span {
        border-bottom: 2px solid #000; }
      #stappen li.finished a i {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #5aa30b; }
    #stappen li.current a, #stappen li.current span {
      color: #0062ac; }
  @media screen and (min-width: 768px) {
    #stappen {
      display: block; }
      #stappen li a, #stappen li span {
        padding-left: 10px; } }
  @media screen and (min-width: 1024px) {
    #stappen li a, #stappen li span {
      font-size: 16px;
      font-size: 1.6rem;
      text-align: center;
      padding-left: 0; } }
  .lt_ie9 #stappen li a, .lt_ie9 #stappen li span {
    font-size: 20px;
    font-size: 2rem;
    text-align: center;
    padding-left: 0; }
  @media screen and (min-width: 1200px) {
    #stappen li a, #stappen li span {
      font-size: 20px;
      font-size: 2rem;
      text-align: center;
      padding-left: 0; } }

#breadcrumb {
  margin: 0 auto 20px;
  color: #999;
  font-size: 11px;
  font-size: 1.1rem; }
  #breadcrumb span {
    display: none; }
  #breadcrumb a {
    color: #999;
    text-decoration: none; }
    #breadcrumb a:hover {
      color: #666; }
  @media screen and (min-width: 480px) {
    #breadcrumb {
      max-width: 400px; } }
  .lt_ie9 #breadcrumb {
    max-width: inherit;
    font-size: 13px;
    font-size: 1.3rem; }
    .lt_ie9 #breadcrumb span {
      display: inline; }
  @media screen and (min-width: 600px) {
    #breadcrumb {
      max-width: inherit;
      font-size: 13px;
      font-size: 1.3rem; }
      #breadcrumb span {
        display: inline; } }

#cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 10px 0;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: none; }
  #cookie-bar #cookie-bar-text {
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    font-size: 1.3rem; }
    #cookie-bar #cookie-bar-text p {
      display: inline-block;
      margin: 0 20px; }
      .lt_ie9 #cookie-bar #cookie-bar-text p {
        display: inline; }
    #cookie-bar #cookie-bar-text a {
      color: #fff;
      text-decoration: underline; }
      #cookie-bar #cookie-bar-text a:hover {
        color: #eee; }
    #cookie-bar #cookie-bar-text #cookie-bar-close {
      background: #fff;
      padding: 5px 20px;
      margin: 10px auto 0;
      color: #000;
      text-decoration: none;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      background-clip: padding-box;
      display: block;
      max-width: 260px; }
      #cookie-bar #cookie-bar-text #cookie-bar-close:hover {
        background: #eee; }
    .lt_ie9 #cookie-bar #cookie-bar-text #cookie-bar-close {
      display: inline; }
    @media screen and (min-width: 768px) {
      #cookie-bar #cookie-bar-text #cookie-bar-close {
        display: inline; } }

.steplist {
  max-width: 640px;
  margin: 0 auto; }
  .steplist ul, .steplist ol {
    margin-bottom: 50px;
    color: #010101; }
    .steplist ul li, .steplist ol li {
      color: #010101;
      font-family: "Agenda", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 22px;
      font-size: 2.2rem; }
      .lt_ie9 .steplist ul li, .lt_ie9 .steplist ol li {
        font-size: 28px;
        font-size: 2.8rem;
        padding: 3px 0; }
      @media screen and (min-width: 768px) {
        .steplist ul li, .steplist ol li {
          font-size: 28px;
          font-size: 2.8rem;
          padding: 3px 0; } }

.usp-blocks {
  padding: 20px 0 0;
  background: #fff; }
  .usp-blocks .container {
    margin: 0 auto; }
    @media screen and (min-width: 768px) {
      html[lang='fr'] .usp-blocks .container {
        display: flex; } }
  .usp-blocks .col {
    position: relative;
    padding: 20px 20px 50px;
    margin: 0 auto 20px;
    box-sizing: border-box;
    display: block;
    width: 300px;
    float: none;
    text-align: center;
    background: #fff; }
    @media screen and (min-width: 480px) {
      .usp-blocks .col {
        width: 100%; } }
    @media screen and (min-width: 768px) {
      .usp-blocks .col {
        display: inline;
        float: left;
        margin: 0 10px 20px 10px; } }
    .usp-blocks .col::after {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 3px solid #ffdf0f; }
    .lt_ie9 .usp-blocks .col {
      min-height: 200px; }
    @media screen and (min-width: 768px) {
      .usp-blocks .col {
        min-height: 200px; } }
  .usp-blocks .titel {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 10px; }
    @media screen and (min-width: 600px) {
      .usp-blocks .titel {
        font-size: 20px;
        font-size: 2rem; } }
    @media screen and (min-width: 768px) {
      .usp-blocks .titel {
        font-size: 20px;
        font-size: 2rem; } }
    @media screen and (min-width: 1024px) {
      .usp-blocks .titel {
        font-size: 24px;
        font-size: 2.4rem; } }
    .lt_ie9 .usp-blocks .titel {
      font-size: 32px;
      font-size: 3.2rem; }
    @media screen and (min-width: 1200px) {
      .usp-blocks .titel {
        font-size: 32px;
        font-size: 3.2rem; } }
  .usp-blocks p {
    margin-bottom: 10px; }
  .usp-blocks .btn {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 20px;
    right: 20px;
    display: block;
    box-sizing: border-box;
    z-index: 1; }
    @media screen and (min-width: 768px) {
      .usp-blocks .btn {
        font-size: 90%; } }
    @media screen and (min-width: 1024px) {
      .usp-blocks .btn {
        font-size: 100%; } }
    .usp-blocks .btn:hover {
      top: auto;
      bottom: -3px; }

/* Layout helpers
----------------------------------*/
.booked-full span {
  background: #0062ac !important;
  color: #fff !important; }

.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */ }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); }

.ui-front {
  z-index: 200; }

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important; }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none;
  z-index: 200 !important; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em; }

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-size: 1.3rem; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%; }

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%; }

.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse !important;
  border-spacing: 0;
  margin: 0 0 .4em;
  padding: 0; }

.ui-datepicker th {
  padding: .7em 0;
  width: 100%/7;
  text-align: center;
  font-weight: bold;
  border: 0;
  color: #111 !important;
  background: transparent !important; }

.ui-datepicker td {
  border: 0 !important;
  padding: 0 !important; }

.ui-datepicker td span,
.ui-datepicker td a {
  display: block !important;
  padding: 3px 3px 3px 0;
  width: 25px;
  margin: 0 1px 1px 0;
  text-align: right;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 12px; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1em; }

.ui-widget-content {
  border-top: 5px solid #0062ac;
  background: #f8f8f8 url(../img/datepicker/ui-bg_flat_75_eee_40x100.png) 50% 50% repeat-x;
  color: #333333;
  width: 220px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5); }

.ui-widget-content a {
  color: #333333; }

.ui-widget-header {
  color: #111;
  font-weight: bold; }

.ui-widget-header a {
  color: #333333; }

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #b4b4b4;
  background: #ffffff url(../img/datepicker/ui-bg_flat_75_eee_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #333333; }

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
  color: #333333;
  text-decoration: none; }

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid #999999;
  background: #eee url(../img/datepicker/ui-bg_flat_75_eee_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #212121; }

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
  color: #212121;
  text-decoration: none; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #aaaaaa;
  background: #ffffff url(../img/datepicker/ui-bg_flat_65_ffffff_40x100.png) 50% 50% repeat-x;
  font-weight: normal;
  color: #212121; }

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #212121;
  text-decoration: none; }

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #5aa30b;
  background: #5aa30b url(../img/datepicker/ui-bg_flat_55_5aa30b_40x100.png) 50% 50% repeat-x;
  color: #fff; }

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #fff; }

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #e31818;
  background: #fef1ec url(../img/datepicker/ui-bg_glass_95_fef1ec_1x400.png) 50% 50% repeat-x;
  color: #e31818; }

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #e31818; }

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #e31818; }

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal; }

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none; }

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35);
  /* For IE8 - See #6059 */ }

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px; }

.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url(../img/datepicker/ui-icons_454545_256x240.png); }

.ui-widget-header .ui-icon {
  background-image: url(../img/datepicker/ui-icons_454545_256x240.png); }

.ui-state-default .ui-icon {
  background-image: url(../img/datepicker/ui-icons_888888_256x240.png); }

.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url(../img/datepicker/ui-icons_454545_256x240.png); }

.ui-state-active .ui-icon {
  background-image: url(../img/datepicker/ui-icons_454545_256x240.png); }

.ui-state-highlight .ui-icon {
  background-image: url(../img/datepicker/ui-icons_fff_256x240.png); }

.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url(../img/datepicker/ui-icons_e31818_256x240.png); }

/* positioning */
.ui-icon-blank {
  background-position: 16px 16px; }

.ui-icon-carat-1-n {
  background-position: 0 0; }

.ui-icon-carat-1-ne {
  background-position: -16px 0; }

.ui-icon-carat-1-e {
  background-position: -32px 0; }

.ui-icon-carat-1-se {
  background-position: -48px 0; }

.ui-icon-carat-1-s {
  background-position: -64px 0; }

.ui-icon-carat-1-sw {
  background-position: -80px 0; }

.ui-icon-carat-1-w {
  background-position: -96px 0; }

.ui-icon-carat-1-nw {
  background-position: -112px 0; }

.ui-icon-carat-2-n-s {
  background-position: -128px 0; }

.ui-icon-carat-2-e-w {
  background-position: -144px 0; }

.ui-icon-triangle-1-n {
  background-position: 0 -16px; }

.ui-icon-triangle-1-ne {
  background-position: -16px -16px; }

.ui-icon-triangle-1-e {
  background-position: -32px -16px; }

.ui-icon-triangle-1-se {
  background-position: -48px -16px; }

.ui-icon-triangle-1-s {
  background-position: -64px -16px; }

.ui-icon-triangle-1-sw {
  background-position: -80px -16px; }

.ui-icon-triangle-1-w {
  background-position: -96px -16px; }

.ui-icon-triangle-1-nw {
  background-position: -112px -16px; }

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px; }

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px; }

.ui-icon-arrow-1-n {
  background-position: 0 -32px; }

.ui-icon-arrow-1-ne {
  background-position: -16px -32px; }

.ui-icon-arrow-1-e {
  background-position: -32px -32px; }

.ui-icon-arrow-1-se {
  background-position: -48px -32px; }

.ui-icon-arrow-1-s {
  background-position: -64px -32px; }

.ui-icon-arrow-1-sw {
  background-position: -80px -32px; }

.ui-icon-arrow-1-w {
  background-position: -96px -32px; }

.ui-icon-arrow-1-nw {
  background-position: -112px -32px; }

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px; }

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px; }

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px; }

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px; }

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px; }

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px; }

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px; }

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px; }

.ui-icon-arrowthick-1-n {
  background-position: 0 -48px; }

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px; }

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px; }

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px; }

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px; }

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px; }

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px; }

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px; }

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px; }

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px; }

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px; }

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px; }

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px; }

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px; }

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px; }

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px; }

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px; }

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px; }

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px; }

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px; }

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px; }

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px; }

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px; }

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px; }

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px; }

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px; }

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px; }

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px; }

.ui-icon-arrow-4 {
  background-position: 0 -80px; }

.ui-icon-arrow-4-diag {
  background-position: -16px -80px; }

.ui-icon-extlink {
  background-position: -32px -80px; }

.ui-icon-newwin {
  background-position: -48px -80px; }

.ui-icon-refresh {
  background-position: -64px -80px; }

.ui-icon-shuffle {
  background-position: -80px -80px; }

.ui-icon-transfer-e-w {
  background-position: -96px -80px; }

.ui-icon-transferthick-e-w {
  background-position: -112px -80px; }

.ui-icon-folder-collapsed {
  background-position: 0 -96px; }

.ui-icon-folder-open {
  background-position: -16px -96px; }

.ui-icon-document {
  background-position: -32px -96px; }

.ui-icon-document-b {
  background-position: -48px -96px; }

.ui-icon-note {
  background-position: -64px -96px; }

.ui-icon-mail-closed {
  background-position: -80px -96px; }

.ui-icon-mail-open {
  background-position: -96px -96px; }

.ui-icon-suitcase {
  background-position: -112px -96px; }

.ui-icon-comment {
  background-position: -128px -96px; }

.ui-icon-person {
  background-position: -144px -96px; }

.ui-icon-print {
  background-position: -160px -96px; }

.ui-icon-trash {
  background-position: -176px -96px; }

.ui-icon-locked {
  background-position: -192px -96px; }

.ui-icon-unlocked {
  background-position: -208px -96px; }

.ui-icon-bookmark {
  background-position: -224px -96px; }

.ui-icon-tag {
  background-position: -240px -96px; }

.ui-icon-home {
  background-position: 0 -112px; }

.ui-icon-flag {
  background-position: -16px -112px; }

.ui-icon-calendar {
  background-position: -32px -112px; }

.ui-icon-cart {
  background-position: -48px -112px; }

.ui-icon-pencil {
  background-position: -64px -112px; }

.ui-icon-clock {
  background-position: -80px -112px; }

.ui-icon-disk {
  background-position: -96px -112px; }

.ui-icon-calculator {
  background-position: -112px -112px; }

.ui-icon-zoomin {
  background-position: -128px -112px; }

.ui-icon-zoomout {
  background-position: -144px -112px; }

.ui-icon-search {
  background-position: -160px -112px; }

.ui-icon-wrench {
  background-position: -176px -112px; }

.ui-icon-gear {
  background-position: -192px -112px; }

.ui-icon-heart {
  background-position: -208px -112px; }

.ui-icon-star {
  background-position: -224px -112px; }

.ui-icon-link {
  background-position: -240px -112px; }

.ui-icon-cancel {
  background-position: 0 -128px; }

.ui-icon-plus {
  background-position: -16px -128px; }

.ui-icon-plusthick {
  background-position: -32px -128px; }

.ui-icon-minus {
  background-position: -48px -128px; }

.ui-icon-minusthick {
  background-position: -64px -128px; }

.ui-icon-close {
  background-position: -80px -128px; }

.ui-icon-closethick {
  background-position: -96px -128px; }

.ui-icon-key {
  background-position: -112px -128px; }

.ui-icon-lightbulb {
  background-position: -128px -128px; }

.ui-icon-scissors {
  background-position: -144px -128px; }

.ui-icon-clipboard {
  background-position: -160px -128px; }

.ui-icon-copy {
  background-position: -176px -128px; }

.ui-icon-contact {
  background-position: -192px -128px; }

.ui-icon-image {
  background-position: -208px -128px; }

.ui-icon-video {
  background-position: -224px -128px; }

.ui-icon-script {
  background-position: -240px -128px; }

.ui-icon-alert {
  background-position: 0 -144px; }

.ui-icon-info {
  background-position: -16px -144px; }

.ui-icon-notice {
  background-position: -32px -144px; }

.ui-icon-help {
  background-position: -48px -144px; }

.ui-icon-check {
  background-position: -64px -144px; }

.ui-icon-bullet {
  background-position: -80px -144px; }

.ui-icon-radio-on {
  background-position: -96px -144px; }

.ui-icon-radio-off {
  background-position: -112px -144px; }

.ui-icon-pin-w {
  background-position: -128px -144px; }

.ui-icon-pin-s {
  background-position: -144px -144px; }

.ui-icon-play {
  background-position: 0 -160px; }

.ui-icon-pause {
  background-position: -16px -160px; }

.ui-icon-seek-next {
  background-position: -32px -160px; }

.ui-icon-seek-prev {
  background-position: -48px -160px; }

.ui-icon-seek-end {
  background-position: -64px -160px; }

.ui-icon-seek-start {
  background-position: -80px -160px; }

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px; }

.ui-icon-stop {
  background-position: -96px -160px; }

.ui-icon-eject {
  background-position: -112px -160px; }

.ui-icon-volume-off {
  background-position: -128px -160px; }

.ui-icon-volume-on {
  background-position: -144px -160px; }

.ui-icon-power {
  background-position: 0 -176px; }

.ui-icon-signal-diag {
  background-position: -16px -176px; }

.ui-icon-signal {
  background-position: -32px -176px; }

.ui-icon-battery-0 {
  background-position: -48px -176px; }

.ui-icon-battery-1 {
  background-position: -64px -176px; }

.ui-icon-battery-2 {
  background-position: -80px -176px; }

.ui-icon-battery-3 {
  background-position: -96px -176px; }

.ui-icon-circle-plus {
  background-position: 0 -192px; }

.ui-icon-circle-minus {
  background-position: -16px -192px; }

.ui-icon-circle-close {
  background-position: -32px -192px; }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

.ui-icon-circle-triangle-s {
  background-position: -64px -192px; }

.ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-icon-circle-triangle-n {
  background-position: -96px -192px; }

.ui-icon-circle-arrow-e {
  background-position: -112px -192px; }

.ui-icon-circle-arrow-s {
  background-position: -128px -192px; }

.ui-icon-circle-arrow-w {
  background-position: -144px -192px; }

.ui-icon-circle-arrow-n {
  background-position: -160px -192px; }

.ui-icon-circle-zoomin {
  background-position: -176px -192px; }

.ui-icon-circle-zoomout {
  background-position: -192px -192px; }

.ui-icon-circle-check {
  background-position: -208px -192px; }

.ui-icon-circlesmall-plus {
  background-position: 0 -208px; }

.ui-icon-circlesmall-minus {
  background-position: -16px -208px; }

.ui-icon-circlesmall-close {
  background-position: -32px -208px; }

.ui-icon-squaresmall-plus {
  background-position: -48px -208px; }

.ui-icon-squaresmall-minus {
  background-position: -64px -208px; }

.ui-icon-squaresmall-close {
  background-position: -80px -208px; }

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px; }

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px; }

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px; }

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px; }

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px; }

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px; }

/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 0; }

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 0; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 0; }

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 0; }

/* Overlays */
.ui-widget-overlay {
  background: #aaaaaa url(../img/datepicker/ui-bg_flat_0_aaaaaa_40x100.png) 50% 50% repeat-x;
  opacity: .3;
  filter: Alpha(Opacity=30); }

.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: #aaaaaa url(../img/datepicker/ui-bg_flat_10_aaaaaa_40x100.png) 50% 50% repeat-x;
  opacity: .3;
  filter: Alpha(Opacity=30);
  border-radius: 8px; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot?v=3.2.1");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=3.2.1") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff?v=3.2.1") format("woff"), url("../fonts/fontawesome-webfont.ttf?v=3.2.1") format("truetype"), url("../fonts/fontawesome-webfont.svg#fontawesomeregular?v=3.2.1") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  *margin-right: .3em; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  text-decoration: inherit;
  display: inline-block;
  speak: none; }

/* makes the font 33% larger relative to the icon container */
.icon-large:before {
  vertical-align: -10%;
  font-size: 1.3333333333333333em; }

/* makes sure icons active on rollover in links */
a [class^="icon-"],
a [class*=" icon-"] {
  display: inline; }

/* increased font size for icon-large */
[class^="icon-"].icon-fixed-width,
[class*=" icon-"].icon-fixed-width {
  display: inline-block;
  width: 1.1428571428571428em;
  text-align: right;
  padding-right: 0.2857142857142857em; }

[class^="icon-"].icon-fixed-width.icon-large,
[class*=" icon-"].icon-fixed-width.icon-large {
  width: 1.4285714285714286em; }

.icons-ul {
  margin-left: 2.142857142857143em;
  list-style-type: none; }

.icons-ul > li {
  position: relative; }

.icons-ul .icon-li {
  position: absolute;
  left: -2.142857142857143em;
  width: 2.142857142857143em;
  text-align: center;
  line-height: inherit; }

[class^="icon-"].hide,
[class*=" icon-"].hide {
  display: none; }

.icon-muted {
  color: #eeeeee; }

.icon-light {
  color: #ffffff; }

.icon-dark {
  color: #333333; }

.icon-border {
  border: solid 1px #eeeeee;
  padding: .2em .25em .15em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.icon-2x {
  font-size: 2em; }

.icon-2x.icon-border {
  border-width: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.icon-3x {
  font-size: 3em; }

.icon-3x.icon-border {
  border-width: 3px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.icon-4x {
  font-size: 4em; }

.icon-4x.icon-border {
  border-width: 4px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px; }

.icon-5x {
  font-size: 5em; }

.icon-5x.icon-border {
  border-width: 5px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

[class^="icon-"].pull-left,
[class*=" icon-"].pull-left {
  margin-right: .3em; }

[class^="icon-"].pull-right,
[class*=" icon-"].pull-right {
  margin-left: .3em; }

/* BOOTSTRAP SPECIFIC CLASSES
 * -------------------------- */
/* Bootstrap 2.0 sprites.less reset */
[class^="icon-"],
[class*=" icon-"] {
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0; }

/* more sprites.less reset */
.icon-white,
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"],
.dropdown-submenu:hover > a > [class^="icon-"],
.dropdown-submenu:hover > a > [class*=" icon-"] {
  background-image: none; }

/* keeps Bootstrap styles with and without icons the same */
.btn [class^="icon-"].icon-large,
.nav [class^="icon-"].icon-large,
.btn [class*=" icon-"].icon-large,
.nav [class*=" icon-"].icon-large {
  line-height: .9em; }

.btn [class^="icon-"].icon-spin,
.nav [class^="icon-"].icon-spin,
.btn [class*=" icon-"].icon-spin,
.nav [class*=" icon-"].icon-spin {
  display: inline-block; }

.nav-tabs [class^="icon-"],
.nav-pills [class^="icon-"],
.nav-tabs [class*=" icon-"],
.nav-pills [class*=" icon-"],
.nav-tabs [class^="icon-"].icon-large,
.nav-pills [class^="icon-"].icon-large,
.nav-tabs [class*=" icon-"].icon-large,
.nav-pills [class*=" icon-"].icon-large {
  line-height: .9em; }

.btn [class^="icon-"].pull-left.icon-2x,
.btn [class*=" icon-"].pull-left.icon-2x,
.btn [class^="icon-"].pull-right.icon-2x,
.btn [class*=" icon-"].pull-right.icon-2x {
  margin-top: .18em; }

.btn [class^="icon-"].icon-spin.icon-large,
.btn [class*=" icon-"].icon-spin.icon-large {
  line-height: .8em; }

.btn.btn-small [class^="icon-"].pull-left.icon-2x,
.btn.btn-small [class*=" icon-"].pull-left.icon-2x,
.btn.btn-small [class^="icon-"].pull-right.icon-2x,
.btn.btn-small [class*=" icon-"].pull-right.icon-2x {
  margin-top: .25em; }

.btn.btn-large [class^="icon-"],
.btn.btn-large [class*=" icon-"] {
  margin-top: 0; }

.btn.btn-large [class^="icon-"].pull-left.icon-2x,
.btn.btn-large [class*=" icon-"].pull-left.icon-2x,
.btn.btn-large [class^="icon-"].pull-right.icon-2x,
.btn.btn-large [class*=" icon-"].pull-right.icon-2x {
  margin-top: .05em; }

.btn.btn-large [class^="icon-"].pull-left.icon-2x,
.btn.btn-large [class*=" icon-"].pull-left.icon-2x {
  margin-right: .2em; }

.btn.btn-large [class^="icon-"].pull-right.icon-2x,
.btn.btn-large [class*=" icon-"].pull-right.icon-2x {
  margin-left: .2em; }

/* Fixes alignment in nav lists */
.nav-list [class^="icon-"],
.nav-list [class*=" icon-"] {
  line-height: inherit; }

/* EXTRAS
 * -------------------------- */
/* Stacked and layered icon */
.icon-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: -35%; }

.icon-stack [class^="icon-"],
.icon-stack [class*=" icon-"] {
  display: block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 1em;
  line-height: inherit;
  *line-height: 2em; }

.icon-stack .icon-stack-base {
  font-size: 2em;
  *line-height: 1em; }

/* Animated rotating icon */
.icon-spin {
  display: inline-block;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear; }

/* Prevent stack and spinners from being taken inline when inside a link */
a .icon-stack,
a .icon-spin {
  display: inline-block;
  text-decoration: none; }

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }

@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

/* Icon rotations and mirroring */
.icon-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1); }

.icon-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2); }

.icon-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); }

.icon-flip-horizontal:before {
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.icon-flip-vertical:before {
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1); }

/* ensure rotation occurs inside anchor tags */
a .icon-rotate-90:before,
a .icon-rotate-180:before,
a .icon-rotate-270:before,
a .icon-flip-horizontal:before,
a .icon-flip-vertical:before {
  display: inline-block; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.icon-glass:before {
  content: "\f000"; }

.icon-music:before {
  content: "\f001"; }

.icon-search:before {
  content: "\f002"; }

.icon-envelope-alt:before {
  content: "\f003"; }

.icon-heart:before {
  content: "\f004"; }

.icon-star:before {
  content: "\f005"; }

.icon-star-empty:before {
  content: "\f006"; }

.icon-user:before {
  content: "\f007"; }

.icon-film:before {
  content: "\f008"; }

.icon-th-large:before {
  content: "\f009"; }

.icon-th:before {
  content: "\f00a"; }

.icon-th-list:before {
  content: "\f00b"; }

.icon-ok:before {
  content: "\f00c"; }

.icon-remove:before {
  content: "\f00d"; }

.icon-zoom-in:before {
  content: "\f00e"; }

.icon-zoom-out:before {
  content: "\f010"; }

.icon-power-off:before,
.icon-off:before {
  content: "\f011"; }

.icon-signal:before {
  content: "\f012"; }

.icon-gear:before,
.icon-cog:before {
  content: "\f013"; }

.icon-trash:before {
  content: "\f014"; }

.icon-home:before {
  content: "\f015"; }

.icon-file-alt:before {
  content: "\f016"; }

.icon-time:before {
  content: "\f017"; }

.icon-road:before {
  content: "\f018"; }

.icon-download-alt:before {
  content: "\f019"; }

.icon-download:before {
  content: "\f01a"; }

.icon-upload:before {
  content: "\f01b"; }

.icon-inbox:before {
  content: "\f01c"; }

.icon-play-circle:before {
  content: "\f01d"; }

.icon-rotate-right:before,
.icon-repeat:before {
  content: "\f01e"; }

.icon-refresh:before {
  content: "\f021"; }

.icon-list-alt:before {
  content: "\f022"; }

.icon-lock:before {
  content: "\f023"; }

.icon-flag:before {
  content: "\f024"; }

.icon-headphones:before {
  content: "\f025"; }

.icon-volume-off:before {
  content: "\f026"; }

.icon-volume-down:before {
  content: "\f027"; }

.icon-volume-up:before {
  content: "\f028"; }

.icon-qrcode:before {
  content: "\f029"; }

.icon-barcode:before {
  content: "\f02a"; }

.icon-tag:before {
  content: "\f02b"; }

.icon-tags:before {
  content: "\f02c"; }

.icon-book:before {
  content: "\f02d"; }

.icon-bookmark:before {
  content: "\f02e"; }

.icon-print:before {
  content: "\f02f"; }

.icon-camera:before {
  content: "\f030"; }

.icon-font:before {
  content: "\f031"; }

.icon-bold:before {
  content: "\f032"; }

.icon-italic:before {
  content: "\f033"; }

.icon-text-height:before {
  content: "\f034"; }

.icon-text-width:before {
  content: "\f035"; }

.icon-align-left:before {
  content: "\f036"; }

.icon-align-center:before {
  content: "\f037"; }

.icon-align-right:before {
  content: "\f038"; }

.icon-align-justify:before {
  content: "\f039"; }

.icon-list:before {
  content: "\f03a"; }

.icon-indent-left:before {
  content: "\f03b"; }

.icon-indent-right:before {
  content: "\f03c"; }

.icon-facetime-video:before {
  content: "\f03d"; }

.icon-picture:before {
  content: "\f03e"; }

.icon-pencil:before {
  content: "\f040"; }

.icon-map-marker:before {
  content: "\f041"; }

.icon-adjust:before {
  content: "\f042"; }

.icon-tint:before {
  content: "\f043"; }

.icon-edit:before {
  content: "\f044"; }

.icon-share:before {
  content: "\f045"; }

.icon-check:before {
  content: "\f046"; }

.icon-move:before {
  content: "\f047"; }

.icon-step-backward:before {
  content: "\f048"; }

.icon-fast-backward:before {
  content: "\f049"; }

.icon-backward:before {
  content: "\f04a"; }

.icon-play:before {
  content: "\f04b"; }

.icon-pause:before {
  content: "\f04c"; }

.icon-stop:before {
  content: "\f04d"; }

.icon-forward:before {
  content: "\f04e"; }

.icon-fast-forward:before {
  content: "\f050"; }

.icon-step-forward:before {
  content: "\f051"; }

.icon-eject:before {
  content: "\f052"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-plus-sign:before {
  content: "\f055"; }

.icon-minus-sign:before {
  content: "\f056"; }

.icon-remove-sign:before {
  content: "\f057"; }

.icon-ok-sign:before {
  content: "\f058"; }

.icon-question-sign:before {
  content: "\f059"; }

.icon-info-sign:before {
  content: "\f05a"; }

.icon-screenshot:before {
  content: "\f05b"; }

.icon-remove-circle:before {
  content: "\f05c"; }

.icon-ok-circle:before {
  content: "\f05d"; }

.icon-ban-circle:before {
  content: "\f05e"; }

.icon-arrow-left:before {
  content: "\f060"; }

.icon-arrow-right:before {
  content: "\f061"; }

.icon-arrow-up:before {
  content: "\f062"; }

.icon-arrow-down:before {
  content: "\f063"; }

.icon-mail-forward:before,
.icon-share-alt:before {
  content: "\f064"; }

.icon-resize-full:before {
  content: "\f065"; }

.icon-resize-small:before {
  content: "\f066"; }

.icon-plus:before {
  content: "\f067"; }

.icon-minus:before {
  content: "\f068"; }

.icon-asterisk:before {
  content: "\f069"; }

.icon-exclamation-sign:before {
  content: "\f06a"; }

.icon-gift:before {
  content: "\f06b"; }

.icon-leaf:before {
  content: "\f06c"; }

.icon-fire:before {
  content: "\f06d"; }

.icon-eye-open:before {
  content: "\f06e"; }

.icon-eye-close:before {
  content: "\f070"; }

.icon-warning-sign:before {
  content: "\f071"; }

.icon-plane:before {
  content: "\f072"; }

.icon-calendar:before {
  content: "\f073"; }

.icon-random:before {
  content: "\f074"; }

.icon-comment:before {
  content: "\f075"; }

.icon-magnet:before {
  content: "\f076"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-retweet:before {
  content: "\f079"; }

.icon-shopping-cart:before {
  content: "\f07a"; }

.icon-folder-close:before {
  content: "\f07b"; }

.icon-folder-open:before {
  content: "\f07c"; }

.icon-resize-vertical:before {
  content: "\f07d"; }

.icon-resize-horizontal:before {
  content: "\f07e"; }

.icon-bar-chart:before {
  content: "\f080"; }

.icon-twitter-sign:before {
  content: "\f081"; }

.icon-facebook-sign:before {
  content: "\f082"; }

.icon-camera-retro:before {
  content: "\f083"; }

.icon-key:before {
  content: "\f084"; }

.icon-gears:before,
.icon-cogs:before {
  content: "\f085"; }

.icon-comments:before {
  content: "\f086"; }

.icon-thumbs-up-alt:before {
  content: "\f087"; }

.icon-thumbs-down-alt:before {
  content: "\f088"; }

.icon-star-half:before {
  content: "\f089"; }

.icon-heart-empty:before {
  content: "\f08a"; }

.icon-signout:before {
  content: "\f08b"; }

.icon-linkedin-sign:before {
  content: "\f08c"; }

.icon-pushpin:before {
  content: "\f08d"; }

.icon-external-link:before {
  content: "\f08e"; }

.icon-signin:before {
  content: "\f090"; }

.icon-trophy:before {
  content: "\f091"; }

.icon-github-sign:before {
  content: "\f092"; }

.icon-upload-alt:before {
  content: "\f093"; }

.icon-lemon:before {
  content: "\f094"; }

.icon-phone:before {
  content: "\f095"; }

.icon-unchecked:before,
.icon-check-empty:before {
  content: "\f096"; }

.icon-bookmark-empty:before {
  content: "\f097"; }

.icon-phone-sign:before {
  content: "\f098"; }

.icon-twitter:before {
  content: "\f099"; }

.icon-facebook:before {
  content: "\f09a"; }

.icon-github:before {
  content: "\f09b"; }

.icon-unlock:before {
  content: "\f09c"; }

.icon-credit-card:before {
  content: "\f09d"; }

.icon-rss:before {
  content: "\f09e"; }

.icon-hdd:before {
  content: "\f0a0"; }

.icon-bullhorn:before {
  content: "\f0a1"; }

.icon-bell:before {
  content: "\f0a2"; }

.icon-certificate:before {
  content: "\f0a3"; }

.icon-hand-right:before {
  content: "\f0a4"; }

.icon-hand-left:before {
  content: "\f0a5"; }

.icon-hand-up:before {
  content: "\f0a6"; }

.icon-hand-down:before {
  content: "\f0a7"; }

.icon-circle-arrow-left:before {
  content: "\f0a8"; }

.icon-circle-arrow-right:before {
  content: "\f0a9"; }

.icon-circle-arrow-up:before {
  content: "\f0aa"; }

.icon-circle-arrow-down:before {
  content: "\f0ab"; }

.icon-globe:before {
  content: "\f0ac"; }

.icon-wrench:before {
  content: "\f0ad"; }

.icon-tasks:before {
  content: "\f0ae"; }

.icon-filter:before {
  content: "\f0b0"; }

.icon-briefcase:before {
  content: "\f0b1"; }

.icon-fullscreen:before {
  content: "\f0b2"; }

.icon-group:before {
  content: "\f0c0"; }

.icon-link:before {
  content: "\f0c1"; }

.icon-cloud:before {
  content: "\f0c2"; }

.icon-beaker:before {
  content: "\f0c3"; }

.icon-cut:before {
  content: "\f0c4"; }

.icon-copy:before {
  content: "\f0c5"; }

.icon-paperclip:before,
.icon-paper-clip:before {
  content: "\f0c6"; }

.icon-save:before {
  content: "\f0c7"; }

.icon-sign-blank:before {
  content: "\f0c8"; }

.icon-reorder:before {
  content: "\f0c9"; }

.icon-list-ul:before {
  content: "\f0ca"; }

.icon-list-ol:before {
  content: "\f0cb"; }

.icon-strikethrough:before {
  content: "\f0cc"; }

.icon-underline:before {
  content: "\f0cd"; }

.icon-table:before {
  content: "\f0ce"; }

.icon-magic:before {
  content: "\f0d0"; }

.icon-truck:before {
  content: "\f0d1"; }

.icon-pinterest:before {
  content: "\f0d2"; }

.icon-pinterest-sign:before {
  content: "\f0d3"; }

.icon-google-plus-sign:before {
  content: "\f0d4"; }

.icon-google-plus:before {
  content: "\f0d5"; }

.icon-money:before {
  content: "\f0d6"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-caret-left:before {
  content: "\f0d9"; }

.icon-caret-right:before {
  content: "\f0da"; }

.icon-columns:before {
  content: "\f0db"; }

.icon-sort:before {
  content: "\f0dc"; }

.icon-sort-down:before {
  content: "\f0dd"; }

.icon-sort-up:before {
  content: "\f0de"; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-linkedin:before {
  content: "\f0e1"; }

.icon-rotate-left:before,
.icon-undo:before {
  content: "\f0e2"; }

.icon-legal:before {
  content: "\f0e3"; }

.icon-dashboard:before {
  content: "\f0e4"; }

.icon-comment-alt:before {
  content: "\f0e5"; }

.icon-comments-alt:before {
  content: "\f0e6"; }

.icon-bolt:before {
  content: "\f0e7"; }

.icon-sitemap:before {
  content: "\f0e8"; }

.icon-umbrella:before {
  content: "\f0e9"; }

.icon-paste:before {
  content: "\f0ea"; }

.icon-lightbulb:before {
  content: "\f0eb"; }

.icon-exchange:before {
  content: "\f0ec"; }

.icon-cloud-download:before {
  content: "\f0ed"; }

.icon-cloud-upload:before {
  content: "\f0ee"; }

.icon-user-md:before {
  content: "\f0f0"; }

.icon-stethoscope:before {
  content: "\f0f1"; }

.icon-suitcase:before {
  content: "\f0f2"; }

.icon-bell-alt:before {
  content: "\f0f3"; }

.icon-coffee:before {
  content: "\f0f4"; }

.icon-food:before {
  content: "\f0f5"; }

.icon-file-text-alt:before {
  content: "\f0f6"; }

.icon-building:before {
  content: "\f0f7"; }

.icon-hospital:before {
  content: "\f0f8"; }

.icon-ambulance:before {
  content: "\f0f9"; }

.icon-medkit:before {
  content: "\f0fa"; }

.icon-fighter-jet:before {
  content: "\f0fb"; }

.icon-beer:before {
  content: "\f0fc"; }

.icon-h-sign:before {
  content: "\f0fd"; }

.icon-plus-sign-alt:before {
  content: "\f0fe"; }

.icon-double-angle-left:before {
  content: "\f100"; }

.icon-double-angle-right:before {
  content: "\f101"; }

.icon-double-angle-up:before {
  content: "\f102"; }

.icon-double-angle-down:before {
  content: "\f103"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

.icon-desktop:before {
  content: "\f108"; }

.icon-laptop:before {
  content: "\f109"; }

.icon-tablet:before {
  content: "\f10a"; }

.icon-mobile-phone:before {
  content: "\f10b"; }

.icon-circle-blank:before {
  content: "\f10c"; }

.icon-quote-left:before {
  content: "\f10d"; }

.icon-quote-right:before {
  content: "\f10e"; }

.icon-spinner:before {
  content: "\f110"; }

.icon-circle:before {
  content: "\f111"; }

.icon-mail-reply:before,
.icon-reply:before {
  content: "\f112"; }

.icon-github-alt:before {
  content: "\f113"; }

.icon-folder-close-alt:before {
  content: "\f114"; }

.icon-folder-open-alt:before {
  content: "\f115"; }

.icon-expand-alt:before {
  content: "\f116"; }

.icon-collapse-alt:before {
  content: "\f117"; }

.icon-smile:before {
  content: "\f118"; }

.icon-frown:before {
  content: "\f119"; }

.icon-meh:before {
  content: "\f11a"; }

.icon-gamepad:before {
  content: "\f11b"; }

.icon-keyboard:before {
  content: "\f11c"; }

.icon-flag-alt:before {
  content: "\f11d"; }

.icon-flag-checkered:before {
  content: "\f11e"; }

.icon-terminal:before {
  content: "\f120"; }

.icon-code:before {
  content: "\f121"; }

.icon-reply-all:before {
  content: "\f122"; }

.icon-mail-reply-all:before {
  content: "\f122"; }

.icon-star-half-full:before,
.icon-star-half-empty:before {
  content: "\f123"; }

.icon-location-arrow:before {
  content: "\f124"; }

.icon-crop:before {
  content: "\f125"; }

.icon-code-fork:before {
  content: "\f126"; }

.icon-unlink:before {
  content: "\f127"; }

.icon-question:before {
  content: "\f128"; }

.icon-info:before {
  content: "\f129"; }

.icon-exclamation:before {
  content: "\f12a"; }

.icon-superscript:before {
  content: "\f12b"; }

.icon-subscript:before {
  content: "\f12c"; }

.icon-eraser:before {
  content: "\f12d"; }

.icon-puzzle-piece:before {
  content: "\f12e"; }

.icon-microphone:before {
  content: "\f130"; }

.icon-microphone-off:before {
  content: "\f131"; }

.icon-shield:before {
  content: "\f132"; }

.icon-calendar-empty:before {
  content: "\f133"; }

.icon-fire-extinguisher:before {
  content: "\f134"; }

.icon-rocket:before {
  content: "\f135"; }

.icon-maxcdn:before {
  content: "\f136"; }

.icon-chevron-sign-left:before {
  content: "\f137"; }

.icon-chevron-sign-right:before {
  content: "\f138"; }

.icon-chevron-sign-up:before {
  content: "\f139"; }

.icon-chevron-sign-down:before {
  content: "\f13a"; }

.icon-html5:before {
  content: "\f13b"; }

.icon-css3:before {
  content: "\f13c"; }

.icon-anchor:before {
  content: "\f13d"; }

.icon-unlock-alt:before {
  content: "\f13e"; }

.icon-bullseye:before {
  content: "\f140"; }

.icon-ellipsis-horizontal:before {
  content: "\f141"; }

.icon-ellipsis-vertical:before {
  content: "\f142"; }

.icon-rss-sign:before {
  content: "\f143"; }

.icon-play-sign:before {
  content: "\f144"; }

.icon-ticket:before {
  content: "\f145"; }

.icon-minus-sign-alt:before {
  content: "\f146"; }

.icon-check-minus:before {
  content: "\f147"; }

.icon-level-up:before {
  content: "\f148"; }

.icon-level-down:before {
  content: "\f149"; }

.icon-check-sign:before {
  content: "\f14a"; }

.icon-edit-sign:before {
  content: "\f14b"; }

.icon-external-link-sign:before {
  content: "\f14c"; }

.icon-share-sign:before {
  content: "\f14d"; }

.icon-compass:before {
  content: "\f14e"; }

.icon-collapse:before {
  content: "\f150"; }

.icon-collapse-top:before {
  content: "\f151"; }

.icon-expand:before {
  content: "\f152"; }

.icon-euro:before,
.icon-eur:before {
  content: "\f153"; }

.icon-gbp:before {
  content: "\f154"; }

.icon-dollar:before,
.icon-usd:before {
  content: "\f155"; }

.icon-rupee:before,
.icon-inr:before {
  content: "\f156"; }

.icon-yen:before,
.icon-jpy:before {
  content: "\f157"; }

.icon-renminbi:before,
.icon-cny:before {
  content: "\f158"; }

.icon-won:before,
.icon-krw:before {
  content: "\f159"; }

.icon-bitcoin:before,
.icon-btc:before {
  content: "\f15a"; }

.icon-file:before {
  content: "\f15b"; }

.icon-file-text:before {
  content: "\f15c"; }

.icon-sort-by-alphabet:before {
  content: "\f15d"; }

.icon-sort-by-alphabet-alt:before {
  content: "\f15e"; }

.icon-sort-by-attributes:before {
  content: "\f160"; }

.icon-sort-by-attributes-alt:before {
  content: "\f161"; }

.icon-sort-by-order:before {
  content: "\f162"; }

.icon-sort-by-order-alt:before {
  content: "\f163"; }

.icon-thumbs-up:before {
  content: "\f164"; }

.icon-thumbs-down:before {
  content: "\f165"; }

.icon-youtube-sign:before {
  content: "\f166"; }

.icon-youtube:before {
  content: "\f167"; }

.icon-xing:before {
  content: "\f168"; }

.icon-xing-sign:before {
  content: "\f169"; }

.icon-youtube-play:before {
  content: "\f16a"; }

.icon-dropbox:before {
  content: "\f16b"; }

.icon-stackexchange:before {
  content: "\f16c"; }

.icon-instagram:before {
  content: "\f16d"; }

.icon-flickr:before {
  content: "\f16e"; }

.icon-adn:before {
  content: "\f170"; }

.icon-bitbucket:before {
  content: "\f171"; }

.icon-bitbucket-sign:before {
  content: "\f172"; }

.icon-tumblr:before {
  content: "\f173"; }

.icon-tumblr-sign:before {
  content: "\f174"; }

.icon-long-arrow-down:before {
  content: "\f175"; }

.icon-long-arrow-up:before {
  content: "\f176"; }

.icon-long-arrow-left:before {
  content: "\f177"; }

.icon-long-arrow-right:before {
  content: "\f178"; }

.icon-apple:before {
  content: "\f179"; }

.icon-windows:before {
  content: "\f17a"; }

.icon-android:before {
  content: "\f17b"; }

.icon-linux:before {
  content: "\f17c"; }

.icon-dribbble:before {
  content: "\f17d"; }

.icon-skype:before {
  content: "\f17e"; }

.icon-foursquare:before {
  content: "\f180"; }

.icon-trello:before {
  content: "\f181"; }

.icon-female:before {
  content: "\f182"; }

.icon-male:before {
  content: "\f183"; }

.icon-gittip:before {
  content: "\f184"; }

.icon-sun:before {
  content: "\f185"; }

.icon-moon:before {
  content: "\f186"; }

.icon-archive:before {
  content: "\f187"; }

.icon-bug:before {
  content: "\f188"; }

.icon-vk:before {
  content: "\f189"; }

.icon-weibo:before {
  content: "\f18a"; }

.icon-renren:before {
  content: "\f18b"; }

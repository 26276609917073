//	FOOTER
	footer {
		clear:both;
		background: $wit;
		@include font-size(14);
		padding-top: $margin-bottom;

			@include mq($widescreen, true) {
				padding-top: $margin-bottom * 2;
			}

		.grijs {
			// background: darken($lichtergrijs, 10%);
			background: lighten($accent2, 25);
      padding-top: $margin-bottom;
			margin-bottom: $margin-bottom;
			@include mq($widescreen, true) {
				padding: ($margin-bottom * 2) 0 $margin-bottom;
				margin-bottom: $margin-bottom * 2;

			}
		}

		.container.cols1, .container.cols2, .container.cols3, .grijs {
			display: none;
			@include mq($tablet, true) {
				display: block;
			}
		}

//	SEO MENU
		.menu {
			@include font-size(14);
			list-style: none;
			margin: 0 0 20px; padding: 0;
			li {
				margin: 0 0 20px;
				a {
					font-weight: bold;
					color: $donkergrijs;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
						color: $accent;
					}
				}
				ul {
					list-style: none;
					li {
						margin: 0;
						a {
							font-weight: normal;
							color: $grijs;
						}
					}
				}
			}
		}



//	FOOTERMENU

		#footermenu {
			border-top: 0;
			margin: 0 10px 20px;
			color: $donkergrijs;
			@include font-size(12);

			ul {
				list-style: none;
				li {
					float: left; display: inline;
					margin: 0 15px 0 0;

					&.item1 {
						font-weight: bold;
					}
					&.item2, &.item3, &.item4, &.item5, &.item6 {
						clear: left;
					}

					a {
						color: $donkergrijs;
						&:hover, &:active {
							color: $accent;
						}
					}
				}
			}

			@include mq($mobile, false) {
				ul {
					display: block;
					width: 380px;
					margin: 0 auto;
				}
			}

			@include mq($smalltablet, false) {
				ul {
					width: 580px;
				}
			}

			@include mq($tablet, true) {
				margin: 0 auto; padding: 10px 0;
				width: 730px;
				border-top: 2px solid $lichtergrijs;
				ul {
					width: auto;
					li {
						clear: none !important;
						&.item1 {
							font-weight: normal;
						}
					}
				}
			}

			@include mq($desktop, false) {
				width: 960px;

			}

			@include mq($widescreen, true) {
				width: 1180px;

			}

		}
	}


// VERTALING

.translation__not-found {
  background-color: $error;
  color: $wit;
  display: inline-block;
  padding: 1px 2px;
}


//	STAPPEN

	#stappen {
		display: none;
		list-style: none;
		margin: $margin-bottom 0;
		li {
			float: left; display: inline;
			width: 25%;

			a, span {
				width: 90%;
				display: block;
				background: $bodycolor;
				text-align: left;
				padding: 10px 0;
				color: $grijs;
				font-family: $titel;
				line-height: $titlelineheight;
				font-weight: normal;
				@include font-size(16);
				text-decoration: none;
			}

			&.finished a {
				color: $zwart;
				position: relative;
				span {
					color: $zwart;
					display: inline;
					border-bottom: 2px solid $lichtgrijs;
					padding: 0;
				}
				&:hover span {
					border-bottom: 2px solid $zwart;
				}
				i {
					position: absolute; right: 10px; top: 10px;
					color: #5aa30b;
				}
			}

			&.current a, &.current span {
				color: $accent;
			}
		}

		@include mq($tablet, false) {
			display: block;
			li {
				a, span {
					padding-left: 10px;
				}
			}
		}

		@include mq($desktop, false) {
			li {
				a, span {
					@include font-size(16);
					text-align: center;
					padding-left: 0;
				}
			}
		}

		@include mq($widescreen, true) {
			li {
				a, span {
					@include font-size(20);
					text-align: center;
					padding-left: 0;
				}
			}
		}
	}


//	BREADCRUMB

	#breadcrumb {
		margin: 0 auto $margin-bottom;
		color: $grijs;
		@include font-size(11);
		span {
			display: none;
		}
		a {
			color: $grijs;
			text-decoration: none;
			&:hover {
				color: $donkergrijs;
			}
		}

		@include mq($mobile, false) {
			max-width: 400px;
		}
		@include mq($smalltablet, true) {
			max-width: inherit;
			@include font-size(13);
			span {
				display: inline;
			}
		}
	}



//	COOKIE-BAR

	#cookie-bar {
		position: fixed; bottom: 0; left: 0; z-index: 100;
		width: 100%; padding: 10px 0;
		background: $zwart;
		background: rgba(0,0,0,0.8);
		color: $wit;
		display: none;

		#cookie-bar-text {
			max-width: 960px;
			margin: 0 auto;
			text-align: center;
			@include font-size(13);
			p {
				display: inline-block;
				margin: 0 $gutter;
				.lt_ie9 & {
					display: inline;
				}
			}
			a {
				color: $wit;
				text-decoration: underline;
				&:hover {
					color: $lichtergrijs;
				}
			}
			#cookie-bar-close {
				background: $wit;
				padding: 5px 20px;
				margin: 10px auto 0;
				color: $zwart;
				text-decoration: none;
				@include rounded(2px);
				display: block;
				max-width: 260px;
				&:hover {
					background: $lichtergrijs;
				}
			}

			@include mq($tablet, true) {
				#cookie-bar-close {
					display: inline;
				}
			}

		}
	}


.steplist {
	max-width: 640px;
	margin: 0 auto;

	ul, ol {
		margin-bottom: 50px;
		color: #010101;

		li {
			color: #010101;
			@include titel();
			@include font-size(22);

			@include mq($tablet, true) {
				@include font-size(28);
				padding: 3px 0;
			}
		}
	}
}

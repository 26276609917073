
//	MENUBTN
	#menubtn {
		position: absolute; left: 10px; top: 20px; z-index: 9999;
		height: 30px; width: 30px; line-height: 30px;
		background: $accent;
		color: $wit;
		font-size: 20px;
		text-decoration: none;
		text-align: center;
		.icon-remove {
			display: none;
		}
		.icon-reorder {
			display: inline;
		}

		&.close {
			background: $wit;
			color: $accent;
			.icon-remove {
				display: inline;
			}
			.icon-reorder {
				display: none;
			}
		}

		@include mq($smalltablet, true) {
			display: none;
		}
	}


//	HOOFDMENU
	header nav {
		position: absolute; left: -100%; top: 0; z-index: 1;
		width: 100%; padding: 70px 0 0; margin: 0;
		background: $accent; overflow: hidden;
		display: block;
		@include transition(left, .2, ease);

		&.show {
			left: 0;
		}

		ul {
			//display: none;
			margin: 0 auto;
			max-width: 1200px;
			li {
				position: relative;
				float: left; zoom: 1;
				margin: 0; width: 100%;
				clear: left; display: block;
				border-top: 1px solid $hoveraccent;
				//padding: 0 10px;
				.lt_ie9 & {
					display: inline;
				}
				a {
					display: block;
	 				text-decoration: none;
	 				color: $wit;
	 				padding: 0 10px;
	 				font-family: $titel;
	 				font-weight: normal;
	 				text-transform: uppercase;
	 				@include font-size(16);
	 				line-height: 35px;
	 				i {
		 				display: none !important;
	 				}
				}
				&.parent a i {
					display: inline !important;
				}
				// HOVER LINK
				&.hover a,
				a:hover {
					//background: darken($accent, 8);
					color: $wit;
				}
				// GESELECTEERDE LINK
				&.selected a {
					background: darken($accent, 5);
					color: $wit;
				}
			}
		}

		@include mq($smalltablet, true) {
			padding: 0;
			width: 100%;
			margin: 0 auto;
			// border-top: 1px solid $lichtgrijs;
			border-top: 0;
			display: block;
			position: static;
			overflow: visible;
			background: $accent;
			height: auto !important;

			#detail & {
				border-bottom: 1px solid $lichtergrijs;
			}

			ul {
				display: block;
				margin: 0 auto;
				height: 45px;
				width: 600px;
				li {
					clear: none;
					padding: 0;
					margin: 0 10px;
					width: auto;
					border: 0;

					a {
						line-height: 45px;
						@include font-size(14);
						padding: 0;
						color: $wit;
					}
					// HOVER LINK
					&.hover a,
					a:hover {
						//background: darken($accent, 8);
						color: $accent2;
					}
					// GESELECTEERDE LINK
					&.selected a {
						color: $accent2;
						background: $accent;
					}
				}
			}
		}
		@include mq($tablet, false) {
			ul {
				width: 750px;
				li {
					margin-right: 10px;
					a {
						@include font-size(16);
					}
				}
			}
		}
		@include mq($desktop, false) {
			ul {
				width: 900px;
        padding-right: 80px;
				li {
					margin-right: 20px;
				}
			}
		}
		@include mq($widescreen, true) {
			ul {
				width: 1200px;
        padding-right: 0;
				li {
					margin-right: 20px;
					a {
						@include font-size(16);
					}
				}
			}
		}
	}

	// SUBMENU
	header nav ul li {

		ul.submenu {
			visibility: visible;
			border-top: 0;
			height: auto;
			margin: 0;
			li {
				font-weight: normal;
				float: left;
				//float: none;
				display: block; clear: left;
				margin: 0;

				a {
					display: block;
					padding: 5px 10px 5px 20px;
					font-family: $font;
					font-weight: bold;
					line-height: $altlineheight;
					@include font-size(13);
					color: $lichtergrijs;
				}
				&:hover ul {
					visibility: visible;
				}
				&.selected a {
					color: $wit;
					background: $hoveraccent;
				}
			}
		}

		@include mq($smalltablet, true) {
			ul.submenu {
				position: absolute; top: 100%; left: -10px; z-index: 2;
				width: 200px;
				visibility: hidden;
				border: 1px solid $hoveraccent;
				border-top: 0;
				height: auto;
				li {
					font-weight: normal;
					float: left;
					//float: none;
					display: block; clear: left;
					width: 200px;
					margin: 0;
					border: 0;
					a {
						display: block;
						padding: 6px 9px;
						font-family: $font;
						line-height: $altlineheight;
						@include font-size(13);
					}
					&:hover ul {
						visibility: visible;
					}

				}
			}
			// OPMAAK SUBMENU LINK
			&.hover ul li a {
				background: $accent;
				color: $wit;
			}
			// OPMAAK SUBMENU HOVERLINK
			&.hover ul li.hover a,
			&:hover ul li a:hover {
				//background: $lichtergrijs;
				color: $lichtergrijs;
			}
			// OPMAAK SUBMENU GESELECTEERDE LINK
			&.hover ul li.selected a {
				background: $accent;
				color: $accent2;
			}
		}
	}

.lang {
  position: absolute;
  right: 10px;
  top: 15px;
  max-width: 42px;

  @include mq($mobile) {
    max-width: 94px;
  }

  @include mq($tablet) {
    top: 40px;
    right: 0;
  }

  @include mq($desktop) {
    top: auto;
    right: 0;
    bottom: -42px;
  }

  a {
    float: left;
    display: inline;
    height: 21px;
    width: 32px;
    text-decoration: none;
    overflow: hidden;
    text-indent: -999px;
    padding: 5px;
    margin-bottom: 5px;

    @include mq($mobile) {
      margin-left: 5px;
    }
  }

  &__nl {
    background: url('../img/nl.gif') 50% no-repeat;

    &--selected {
      background-color: $accent2;
    }
  }

  &__fr {
    background: url('../img/fr.gif') 50% no-repeat;

    &--selected {
      background-color: $accent2;
    }
  }

}

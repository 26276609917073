.usp-blocks {
  padding: 20px 0 0;
  background: $wit;

  .container {
    margin: 0 auto;

    html[lang='fr'] & {
      @include mq($tablet) {
        display: flex;
      }
    }
  }

  .col {
    position: relative;
    padding: 20px 20px 50px;
    margin: 0 auto 20px;
    box-sizing: border-box;
    display: block;
    width: 300px;
    float: none;
    text-align: center;
    background: $wit;

    @include mq($mobile) {
      width: 100%;
    }

    @include mq($tablet) {
      display: inline;
      float: left;
      margin: 0 10px 20px 10px;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 3px solid $accent2;
    }

    @include mq($tablet, true) {
      min-height: 200px;
    }
  }

  .titel {
    @include font-size(16);
    margin-bottom: 10px;

    @include mq($smalltablet, false) {
      @include font-size(20);
    }

    @include mq($tablet, false) {
      @include font-size(20);
    }

    @include mq($desktop, false) {
      @include font-size(24);
    }

    @include mq($widescreen, true) {
      @include font-size(32);
    }
  }

  p {
    margin-bottom: 10px;
  }

  .btn {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 20px;
    right: 20px;
    display: block;
    // width: 100%;
    box-sizing: border-box;
    z-index: 1;

    @include mq($tablet) {
      font-size: 90%;
    }

    @include mq($desktop) {
      font-size: 100%;
    }



    &:hover {
      top: auto;
      bottom: -3px;
    }
  }
}

@font-face {
    font-family: 'agenda';
    src: url('../fonts/agenda-bold-webfont.eot');
    src: url('../fonts/agenda-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/agenda-bold-webfont.woff') format('woff'),
         url('../fonts/agenda-bold-webfont.ttf') format('truetype'),
         url('../fonts/agenda-bold-webfont.svg#agendabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
